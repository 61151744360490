import React, { useEffect, useState } from "react";
import socket from "../../../../../socket.js";
import appUrl from "../../../../constants/appUrl.js";
import { axiosApi } from "../../../../services/axios_api.js";

import CustomHeader from "../../../components/custom-header.js";
import MapLocation from "../components/map_location.js";
import StatsCardsItem from "../components/stats_cards.js";

export default function DashboardScreen() {
    const [cards, setCards] = useState({});
    const [mapData, setMapData] = useState();
const [agentsLocation , setAgentsLocation]= useState([])

    const getDashboardStat = async () => {
        const result = await axiosApi.get(`${appUrl.baseUrl}api/stat`);
        if (result) {
            setCards(result?.data);
        }
    };
    const getMapData = async () => {
        const result = await axiosApi.get(`${appUrl.baseUrl}api/stat/mapdata`);
        if (result) {
            setMapData(result?.data);
        }
    };
    useEffect(() => {
        getDashboardStat();
        getMapData();
        socket.emit('agentsLocation', { foo: 'bar' }, (response) => {
            const {data}=response
            setAgentsLocation(data)
            
           });
           socket.on('userLocation', (data)=>{
         const updateAgentsLocation = agentsLocation.map((item)=> {
            if(item._id == data.userId)
            {
                item.lastLocation=data.lastLocation
            }
            return item
            
         })
         setAgentsLocation(updateAgentsLocation)
 
           })
           return () => {
           
            socket.off('userLocation')
          };

    }, []);

    return (
        <>
            <CustomHeader title={"Dashboard"} />
            <StatsCardsItem data={cards} />
            <MapLocation agents={agentsLocation} />
        </>
    );
}
