import React, { useEffect, useState } from 'react'
import socket from '../../../../../socket.js';
import { axiosApi } from "../../../../services/axios_api.js";
import MapLocation from "../components/duplicate_map.js";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import './alert_screen.css';
import { DataTable } from "primereact/datatable";
import Timer from '../components/timer.js';

export default function AlertScreen() {
    const [mapData, setMapData] = useState();
    const [singleMapData, setSingleMapData] = useState([]);
    const [dialogVisible, setDialogVisible] = useState(false);
const [stat,setStat]=useState({})
const [unClosedAlerts,setUnClosedAlerts]=useState([])
const [alerts,setAlerts]=useState([])
const [agentsLocation , setAgentsLocation]= useState([])
    const getMapData = async () => {
        const result = await axiosApi.get("http://localhost:3001/api/stat/mapdata");
        if (result) {
            setMapData(result?.data);
        }
    };
    useEffect(()=>{
console.log({unClosedAlerts})
    },[unClosedAlerts])
    useEffect(() => {
        // Listen for the newAlert event
        socket.on('newAlert', (response) => {
            socket.emit('alertList', { foo: 'bar' }, (response) => {
                const {data,stat}=response
                setAlerts(data)
                 setStat(stat[0])
                
               });
           
        });

        socket.emit('alertList', { foo: 'bar' }, (response) => {
           const {data,stat}=response
           setAlerts(data)
            setStat(stat[0])
           
          });
          socket.emit('agentsLocation', { foo: 'bar' }, (response) => {
            const {data}=response
            setAgentsLocation(data)
            
           });

           socket.emit('unClosedAlert', { }, (response) => {
            const {data}=response
            setUnClosedAlerts(data)
            
           });
           socket.on('userLocation', (data)=>{
            const updateAgentsLocation = agentsLocation.map((item)=> {
               if(item._id== data.userId)
               {
                   item.lastLocation=data.lastLocation
               }
               return item
               
            })
            setAgentsLocation(updateAgentsLocation)
    
              })  
    
        // Unsubscribe from the newAlert event when the component unmounts
        return () => {
          socket.off('newAlert');
          socket.off('userLocation')
        };
      }, []);
    useEffect(() => {
        
        getMapData();
    }, []);
    // const DashboardReducer = useSelector((state) => state.dashboardModule);
    // const { data: dashboardData } = DashboardReducer;
    const heading = (heading) => {
        return <h3 className='text-white custom__css'>
            {heading}
        </h3>
    }
    const value = (value) => {
        return <h1 className='text-white custom__css'>
            {value}
        </h1>
    }

    // function toTitleCase(str) {
    //     return str.toLowerCase().split(' ').map(function (word) {
    //         return (word.charAt(0).toUpperCase() + word.slice(1));
    //     }).join(' ');
    // }
      const createdAt = "2022-03-06T12:34:56.000Z"; // Replace with actual createdAt value from API

    useEffect(() => {
        // Subscribe to a socket event
        socket.on('my-event', (data) => {
          console.log(data);
        });
    
        // Unsubscribe from the socket event when the component unmounts
        return () => {
          socket.off('my-event');
        };
      }, []);
      
    //   const toggleDialog = () => {
    //     setDialogVisible(!dialogVisible);
    //   };
    
    const handleDialog=(d)=>{
      
        setSingleMapData([d])
        setDialogVisible(true)
    }

    return (
        <>
     
<Dialog visible={dialogVisible} onHide={() => setDialogVisible(false)}
dialogClassName="custom-dialog-class">
    <MapLocation agents={[]} alerts={singleMapData} />
</Dialog> 





        <div className='grid'>
        
            <div className='col-12 md:col-3 sm:col-6'>
                <div className='flex flex-column bg-green-500  p-5 border-round fixed__height '>
                    {heading('Open Alerts')}
                    {value(stat?.counts?.open)}
                </div>
            </div>
            <div className='col-12 md:col-3 sm:col-6'>
                <div className='flex flex-column bg-teal-300 p-5 border-round fixed__height '>
                    {heading('Close Alerts')}
                    {value(stat?.counts?.closed)}
                    </div>
            </div>
            <div className='col-12 md:col-3 sm:col-6'>
                <div className='flex flex-column bg-purple-400 p-5 border-round fixed__height '>
                   
                    {heading(' False Alerts')}
                    {value(stat?.counts?.falseAlert)}

                   </div>
            </div>
            <div className='col-12 md:col-3 sm:col-6'>
                <div className='flex flex-column bg-orange-400 p-5 border-round fixed__height '>
                    {heading(' Inprogress')}
                    {value(stat?.counts?.inProgress)}

                 </div>
            </div>
         
         
           
        </div>
         <MapLocation agents={agentsLocation} alerts={unClosedAlerts}/> 
    
           

                <DataTable rows={10} paginator responsiveLayout="scroll" value={alerts} key="_id" emptyMessage="No record found."
                    globalFilterFields={[
                        "driver.firstName",
                        "driver.lastName",
                        "driver.cnic",
                        "driver.email",
                        "driver.zindigiWallet.zindigiWalletNumber",
                    ]}>

                    <Column filter field="name" header="Name" />
                    <Column filter field="mobile" header="Mobile" />
                    <Column filter field="location.address" header="Address" body={(rowData) => {
                               const address = rowData.location.address;
                                const shortAddress = address.length > 20 ? `${address.slice(0, 20)}...` : address;
                                 return <span>{shortAddress}</span>;
                                }} />
                   <Column 
                        filter 
                        field="" 
                        header="Responded By" 
                        body={(rowData) => rowData.respondedBy ? rowData.respondedBy.first_name + " " + rowData.respondedBy.last_name : "N/A"}
                        />
                    <Column filterField="respondedAt"
                            header="Responded Time"
                            body={(rowData) => {
                                const respondedAt = new Date(rowData.respondedAt);
                                const date = respondedAt.toLocaleDateString();
                                const time = respondedAt.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                                return `${date} ${time}`;
                            }}
                            />
                    <Column filter field=""  header="Time Elapsed"  body={(rowData) => <Timer createdAt={rowData?.createdAt} />}/>
                    <Column filter field="closedTime" header="Close Time" />
                    <Column filter field="createdAt" header="Created Time" />
                    <Column filter field="category" header="Category" />
                    <Column field="" header="Action" body={(rowData) => <Button onClick={(e) =>{e.preventDefault(); handleDialog(rowData)}} label="Location" />} />



                </DataTable>
              
                
          
        </>
    )
}
