import Axios from 'axios';
import appUrl from '../../constants/appUrl';
// import { axiosApi } from '../../app/features/constants/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const stationSlice = createSlice({
    name: 'stationSlice',
    initialState: {},
    reducers: {
        resetAddeditStation(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined }
        }


    },
    extraReducers: (builder) => {
        builder
            .addCase(getStationList.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getStationList.fulfilled, (state, action) => {

                return { ...state, loading: false, data: action.payload }
            })
            .addCase(getStationList.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });

        builder
            .addCase(addStation.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addStation.fulfilled, (state, action) => {

                return { ...state, addLoading: false, addData: action.payload, addSuccess: true }
            })
            .addCase(addStation.rejected, (state, action) => {

                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false
                }
            });
        builder
            .addCase(editStation.pending, (state, action) => {
                return { ...state, editLoading: true }
            })
            .addCase(editStation.fulfilled, (state, action) => {

                return { ...state, editLoading: false, editData: action.payload, editSuccess: true }
            })
            .addCase(editStation.rejected, (state, action) => {

                return {
                    ...state, editLoading: false,
                    error: action.payload,
                    editSuccess: false
                }
            });
        builder
            .addCase(deleteStation.pending, (state, action) => {
                return { ...state, deleteLoading: true }
            })
            .addCase(deleteStation.fulfilled, (state, action) => {

                return { ...state, deleteLoading: false, deleteData: action.payload, deleteSuccess: true }
            })
            .addCase(deleteStation.rejected, (state, action) => {

                return {
                    ...state, deleteLoading: false,
                    error: action.payload,
                    deleteSuccess: false
                }
            });
    },
});

export default stationSlice.reducer;
export const { resetAddeditStation } = stationSlice.actions;



// Thunks
export const getStationList = createAsyncThunk('getStationList/fetch', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.get(appUrl.baseUrl + appUrl.getStationList);
         console.log(data);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const addStation = createAsyncThunk('addStation/add', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.post(appUrl.baseUrl + appUrl.addStation, body);
console.log(data);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }
});
export const editStation = createAsyncThunk('editStation/edit', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.put(appUrl.baseUrl + appUrl.editStation, body);
        return fulfillWithValue(data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }

});
export const deleteStation = createAsyncThunk('station/delete', async (psId, { rejectWithValue, fulfillWithValue }) => {
    try {

        const { data } = await Axios.delete(appUrl.baseUrl + appUrl.deleteStation + `?ps=ps&user=${psId}`);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }
});
