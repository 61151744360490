import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { useDispatch, useSelector } from "react-redux";
import { confirmDialog } from 'primereact/confirmdialog';

import AddStation from '../components/add_station';
import AddStationOfficer from '../components/add_station_officer';
import { deleteStation, getStationList } from "../../../../redux/slices/station_slice";
export default function StationScreen() {
    const stationListReducer = useSelector((state) => state.stationList);
    const { data, loading,deleteSuccess } = stationListReducer;
    // //console.log("adminUsers checking admin users",adminUsers)
    let psId;
    const [showAddUserDialog, setAddUserDialog] = useState(false);
    const [showAddStationDialog, setAddStationDialog] = useState(false);

    const [expandedRows, setExpandedRows] = useState(null)

    const [editUser, setEditUser] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const dialogFuncMap = {
        showAddUserDialog: setAddUserDialog,
        showAddStationDialog: setAddStationDialog,

    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    useEffect(() => {

        if (deleteSuccess !== undefined) {
          if (deleteSuccess === true) {
            dispatch(getStationList());
            // toast.warn("Deleted Successfully")
    
          } else {
    
          }
        }
      }, [deleteSuccess])
      const confirmDeleteAd = () => {
        confirmDialog({
          message: 'Do you want to delete this ad?',
          header: 'Delete Confirmation',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          accept,
    
        });
      };
      const accept = () => {
        dispatch(deleteStation(psId));
        console.log(psId)
      }
    const actionTemplate = (rowData) => {
        return (
            <>
                <div className="Edit_Icon">


                    <Button
                        tooltip="Add Sub Module"
                        icon="pi pi-trash"
                        tooltipOptions={{ position: "top" }}
                        className="edit m-2 p-button-rounded p-button-danger"
                        onClick={() => {
                            psId = rowData._id;
                            confirmDeleteAd();
                        }}
                    />
                </div>
              
            </>
        );
    };
    const addTemplate = (rowData) => {
        return (
            <>
                <div className="Edit_Icon">
                    <Button
                        label="Add "
                        className="p-button-primary ml-3 p-button-rounded p-button-sm custom___width"
                        onClick={() => {
                            setEditUser(null);
                            onClick("showAddStationDialog");
                        }}
                    />


                </div>
            </>
        );
    };
    const rowExpansionTemplate = (rowData) => {

        return <DataTable
            showGridlines={true}
            value={rowData.officers}
        >
            <Column
                field='first_name'
                header='First Name'
            />

            <Column
                field='last_name'
                header='Last Name '
            />
            {/* <Column body={actionTemplate} header="Actions"></Column> */}

        </DataTable>
    }
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getStationList());

    }, []);
    const allowExpansion = (rowData) => {
        return <>
            {rowData.length > 0}
        </>
    }
    return (
        <>
            {/* <Dialog header={editUser == null ? 'Add User' : 'Edit User'} visible={showAddUserDialog} onHide={() => onHide('showAddUserDialog')} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '70vw' }} className="Modal_class">
                <AddStation editData={editUser} onHide={() => onHide("showAddUserDialog")} />
            </Dialog> */}
            <Dialog header={editUser == null ? 'Add User' : 'Edit User'} visible={showAddStationDialog} onHide={() => onHide('showAddStationDialog')} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '70vw' }} className="Modal_class">
                <AddStationOfficer editData={editUser} onHide={() => onHide("showAddStationDialog")} />
            </Dialog>
            <div className="card">
                {/* <div className='flex flex-row justify-content-between align-content-center align-items-center pb-3'> */}
                <div className="grid pb-3">
                    <div className="col-12 md:col-6">
                        <h4>Zone</h4>
                    </div>
                    {/* <div className=" col-12 md:col-6 mb-9 text-right">
                        <span className="p-input-icon-right">
                            <InputText className="mbl_view" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                            <Button
                                label="New Zone"
                                className="p-button-primary ml-3 p-button-rounded p-button-sm custom___width"
                                onClick={() => {
                                    setEditUser(null);
                                    onClick("showAddUserDialog");
                                }}
                            />
                        </span>
                    </div> */}
                    {/* <div className='col-12 md:col-6'>
                        <div className="mb-9 text-right">
                            <span className="p-input-icon-right">
                              
                            </span>
                            <Button label='Add new' className='p-button-success p-button-rounded p-button-sm custom___width' onClick={() => {
                                setEditUser(null);
                                onClick('showAddUserDialog')
                            }} />
                        </div>
                    </div> */}
                </div>

                <DataTable rows={10} paginator responsiveLayout="scroll" value={data} key="_id" emptyMessage="No record found."
                    expandedRows={expandedRows}
                    onRowToggle={(e) => { setExpandedRows(e.data) }}
                    rowExpansionTemplate={rowExpansionTemplate}
                    globalFilterFields={[

                    ]}>
                    <Column expander={allowExpansion} style={{ width: '3em' }} />
                    <Column filter field="name" header="Reporting Station Name" />
                    {/* <Column filter field="" header="Officers" /> */}
                    <Column body={addTemplate} header="Actions"></Column>

                </DataTable>
            </div>
        </>
    );
}
