import React, { useState, useEffect } from "react";

function Timer({ createdAt }) {
  const [timeElapsed, setTimeElapsed] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const elapsedTime = Math.floor((now - new Date(createdAt).getTime()) / 1000);
      setTimeElapsed({
        days: Math.floor(elapsedTime / (24 * 60 * 60)),
        hours: Math.floor((elapsedTime % (24 * 60 * 60)) / (60 * 60)),
        minutes: Math.floor((elapsedTime % (60 * 60)) / 60),
        seconds: elapsedTime % 60,
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [createdAt]);
  const timeElapsedString = `${timeElapsed.days} days, ${timeElapsed.hours} hours, ${timeElapsed.minutes} minutes, ${timeElapsed.seconds} seconds`;

  return (
    <div>
      {timeElapsed.days} days, {timeElapsed.hours} hours, {timeElapsed.minutes} minutes, {timeElapsed.seconds} seconds
    </div>
  );
}

export default Timer;