// import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button } from "primereact/button";
// import { toast } from "react-toastify";
import { InputSwitch } from 'primereact/inputswitch';
import CustomInputField from "../../../../components/custom_input_field";
import { addUser, getUserList, resetAddEditPolls } from "../../../../../redux/slices/user_slice";
import { getRolesList } from "../../../../../redux/slices/roles_slice";
import { toast } from "react-toastify";
// import { addTutorialsCategory, getTutorialsCategoryList, resetAddEditTutorialsCategory, updateTutorialsCategory } from "../../../../../redux/slices/tutorials_category_slice";

export default function WebUserList({ onHide, editData })
 {  const ListUserReducer = useSelector((state) => state.userList);
    const { data, addLoading, addSuccess} = ListUserReducer;
 
    const rolesListReducer = useSelector((state) => state.rolesList);
    const { data:list  } = rolesListReducer;
    //states
   

    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required("name  is required"),
        last_name: Yup.string().required("name is required"),
        email: Yup.string().required("email is required"),
        mobile: Yup.string().required("mobile is required"),
        role: Yup.string().required("role is required"),
        password: Yup.string().required("password is required"),
        userName: Yup.string().required("pin is required"),

        // password: Yup.string().required("password is required"),
        // pin: Yup.string().required("pin is required"),


    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            first_name: "",
            last_name:"",
            userName:"",
            email:"",
            password:"",
            mobile:"",
            role:"",
        },

        onSubmit: async (data) => {
            console.log(data)
            if (editData === null) {
                console.log(data)
                dispatch(addUser(data));
                toast.success("Web user added");
                onHide();
               
            } else {
                // data["categoryId"] = editData._id;
                // dispatch(updateTutorialsCategory(data));
                //console.log(data)
            }
        },
    });
    useEffect(() => {
        dispatch(getRolesList('web')); 
    
    }, []);
    useEffect(() => {


        if (addSuccess !== undefined) {
          if (addSuccess === true) {
            // toast.success("Advertisement added");
    
            onHide();
            dispatch(getUserList('web'));
    
          } else {
            // toast.error(addError);
    
          }
        }
    
       
    
        return () => {
          dispatch(resetAddEditPolls());
        }
    
    
      }, [addSuccess]);
    return (
        <>
            <form  onSubmit={formik.handleSubmit}>
                <div className="">
                    <div className="grid p-fluid">
                    <div className="col-12">
                            <CustomInputField iden="userName" label="Username" type="text" formik={formik} />
                        </div>
                        <div className="col-12">
                            <CustomInputField iden="first_name" label="First Name" type="text" formik={formik} />
                        </div>
                        
                        <div className="col-12">
                            <CustomInputField iden="last_name" label="Last Name" type="text" formik={formik} />
                        </div>
                       
                        <div className="col-12">
                            <CustomInputField iden="email" label="Email" type="text" formik={formik} />
                        </div>
                        <div className="col-12">
                            <CustomInputField iden="mobile" label="Mobile" type="text" formik={formik} />
                        </div>
                        <div className="col-12">
                            <CustomInputField iden="password" label="Password" type="text" formik={formik} />
                        </div>
                        <div className="col-12">
                            <CustomInputField type='dropdown' iden='role' label="Choose Role" formik={formik} optionLabel="roleTitle" optionValue="_id" options={list} placeHolder="Please Select" />
                        </div>
                      
                        <div className="col-12">
                            <div className="button_class-modal">
                                <Button
                                    //loading={editData === null ? addLoading : updateLoading}
                                    type="submit" label={editData === null ? "Add" : "Edit"} className="p-button-primary btn__default_modal p-button-rounded" />
                            </div>
                        </div>
                    </div>
                </div>
            </form> 
              
        </>
    );
}
