// import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button } from "primereact/button";
// import { toast } from "react-toastify";
import CustomInputField from "../../../components/custom_input_field";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Chips } from "primereact/chips";
import { addFields, resetAddEditFields,updateFields } from "../../../../redux/slices/add_field_slice.js";
import { getPollsList } from "../../../../redux/slices/polls_slice";
import { toast } from "react-toastify";

export default function AddEditForm({ onHide, editData, selectedPolls }) {
    //redux
    const addFieldReducer = useSelector((state) => state.addField);
    const { data: pollList, addSuccess, addError, updateError, updateSuccess } = addFieldReducer;
    // const fieldReducer = useSelector((state) => state.addFields);
    // const { data, } = fieldReducer;
    //dropdown hooks
    const [selectedOption, setSelectedOption] = useState("textfield");
    const [value, setvalue] = useState([]);
    const [radioValue, setRadioValue] = useState([]);
    // const [subPollList,setSubPollList] = useState([]);

    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        //type: Yup.string().required("Field type is required"),
        //textType: Yup.string().required("Text Type Hint is required"),
        enLabel: Yup.string().required("English Label type is required"),
        urLabel: Yup.string().required("Urdu Label type is required"),
        enHint: Yup.string().required("English Hint is required"),
        urHint: Yup.string().required("Urdu Hint is required"),
    });
    useEffect(() => {
        if (pollList === undefined) {
            dispatch(getPollsList());
        }
    }, []);
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            //type : "",
            //textType: "",
            enLabel: "",
            urLabel: "",
            enHint: "",
            urHint: "",
            isOptional: false,
            isActive: true,
            visible: false,
        },

        onSubmit: async (data) => {
            let body = {
                catId: selectedPolls,
                type: selectedOption,
                options: radioValue.map((item) => {
                    return {
                        title: item,
                        value: item,
                    };
                }),
            };
            const bothData = { ...data, ...body };
            if (editData === null) {
                dispatch(addFields(bothData));
            } else {
                data["fieldId"] = editData._id;
                data["catId"] = selectedPolls;
                dispatch(updateFields(data));
            }
        },
    });
    useEffect(() => {
        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("Field added");
                onHide();
                dispatch(getPollsList());
            } else {
                toast.error(addError);
            }
        }
        if (updateSuccess !== undefined) {
            if (updateSuccess === true) {
                toast.success("Field updated");
                onHide();
                dispatch(getPollsList());
            } else {
                toast.error(updateError);
            }
        }
        return () => {
            dispatch(resetAddEditFields());
        };
    }, [addSuccess, addError]);

    //dropdown value change
    const fieldTypeValue = [
        { label: "Text Field", type: "textfield" },
        { label: "Textarea", type: "textarea" },
        { label: "Dropdown", type: "dropdown" },
        { label: "Radio Buttons", type: "radio" },
        { label: "Multivalue Textfield", type: "multivalue" },
        { label: "Date", type: "date" },
        { label: "Time", type: "time" },
    ];

    const textTypeValue = [
        { label: " Text", textType: "text" },
        { label: " Number", textType: "number" },
        { label: " Phone", textType: "phone" },
    ];
    const loadInitialValues = () => {
        formik.setFieldValue("enLabel", editData.en_label);
        formik.setFieldValue("urLabel", editData.ur_label);
        formik.setFieldValue("enHint", editData.en_hint);
        formik.setFieldValue("urHint", editData.ur_hint);
        formik.setFieldValue("textType", editData.textType);

        formik.setFieldValue("type", selectedOption);
        setSelectedOption(editData.type);

        formik.setFieldValue("isActive", editData.isActive);
        formik.setFieldValue("isOptional", editData.isOptional);
        formik.setFieldValue("visible", editData.visible);
    };
    //properties
    useEffect(() => {
        if (editData != null) {
            loadInitialValues();
        }
    }, []);
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="">
                    <div className="grid p-fluid">
                        {/* {pollList.map((poll) => {
                            return (
                                poll.subCat.map((sub) => {  
                                    //console.log("ddddd",sub)
                                    return (
                                        <>
                                            <div className="col-12">
                                                <CustomInputField type="dropdown" iden="ur_subCategoryTitle" label="Sub Poll" name="ur_subCategoryTitle" options={sub} formik={formik} optionLabel="ur_subCategoryTitle" optionValue="_id" placeHolder="Please Select" />
                                            </div>
                                        </>
                                    )
                                })
                            )

                        })} */}

                        <div className="col-12">
                            <Dropdown
                                options={fieldTypeValue}
                                optionLabel="label"
                                optionValue="type"
                                value={selectedOption}
                                onChange={(e) => {
                                    setSelectedOption(e.value);
                                }}
                            />
                            {/* <CustomInputField value={selectedOption} type='dropdown' iden='type' label="Choose field" formik={formik} optionLabel="label" optionValue="type" options={fieldTypeValue} placeHolder="Please Select" /> */}
                        </div>
                        {selectedOption === "textfield" && (
                            <>
                                <div className="col-12">
                                    <CustomInputField type="dropdown" iden="textType" label="Choose field" formik={formik} optionLabel="label" optionValue="textType" options={textTypeValue} placeHolder="Please Select" />
                                </div>
                            </>
                        )}
                        <div className="col-12">
                            <CustomInputField iden="enLabel" label="English Label" type="text" formik={formik} />
                        </div>
                        <div className="col-12">
                            <CustomInputField iden="urLabel" label="اردو لیبل" type="text" formik={formik} />
                        </div>
                        <div className="col-12">
                            <CustomInputField iden="enHint" label="English Hint" type="text" formik={formik} />
                        </div>
                        <div className="col-12">
                            <CustomInputField iden="urHint" label="اردو اشارہ" type="text" formik={formik} />
                        </div>

                        {(selectedOption === "radio" || selectedOption === "dropdown") && (
                            <>
                                <div className="col-12">
                                    <label>Dropdown/Radio Buttons</label>
                                    <Chips
                                        value={radioValue}
                                        onChange={(e) => {
                                            const lowercaseArray = e.value.map((s) => s.toLowerCase());

                                            // Check for duplicates
                                            if (new Set(lowercaseArray).size !== lowercaseArray.length) {
                                                return;
                                            } else {
                                                setRadioValue(e.value);
                                            }
                                        }}
                                    />
                                </div>
                            </>
                        )}
                        <div className="col-12">
                            <CustomInputField iden="isOptional" formik={formik} label="Is Optional" type="checkbox" className="field col-12 md:col-4" />
                        </div>
                        <div className="col-12">
                            <CustomInputField iden="isActive" formik={formik} label="Is Active" type="checkbox" className="field col-12 md:col-4" />
                        </div>
                        <div className="col-12">
                            <CustomInputField iden="visible" formik={formik} label="Visible" type="checkbox" className="field col-12 md:col-4" />
                        </div>
                        <div className="col-12">
                            <div className="button_class-modal">
                                <Button
                                    //loading={editData === null ? addLoading : updateLoading}
                                    type="submit"
                                    label={editData === null ? "Add" : "Edit"}
                                    className="p-button-primary btn__default_modal p-button-rounded"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}
