import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Accordion, AccordionTab } from 'primereact/accordion';
import AddEditPolls from "../components/add_edit_polls_dailoge";
import AddEditSubPolls from "../components/add_edit_sub_polls_dailoge";
import AddEditForm from "../components/add_edit_form";
import { deletePolls, getPollsList, resetAddEditPolls } from "../../../../redux/slices/polls_slice";
import { toast } from "react-toastify";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { deleteSubPolls, resetAddEditSubPolls } from "../../../../redux/slices/polls_category_slice";

export default function PollsScreen() {
    //states
    const [expandedRows, setExpandedRows] = useState(null);
    const [editPoll, setEditPoll] = useState(null);
    const [editSubPoll, setEditSubPoll] = useState(null);
    const [editForm, setEditForm] = useState(null);
    const [showAddEditPollDialog, setAddEditPollDialog] = useState(false);
    const [showAddEditSubPollDialog, setAddEditSubPollDialog] = useState(false);
    const [showAddEditFormDailoge, setAddEditFormDailoge] = useState(false);
    const [selectedPolls, setSelectedPolls] = useState(null);
    const [catId,setCatId] = useState("")
    //hooks
    const pollsListReducer = useSelector((state) => state.pollsList);
    const { data, deleteError, deleteSuccess } = pollsListReducer;
    const subPollListReducer = useSelector((state) => state.subPollsList);
    const { deleteSubSuccess, deleteSubError } = subPollListReducer;

    const dialogFuncMap = {
        showAddEditPollDialog: setAddEditPollDialog,
        showAddEditSubPollDialog: setAddEditSubPollDialog,
        showAddEditFormDailoge: setAddEditFormDailoge,
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };
    


    //Poll delete dailoge
    let deletedId='';
    const accept = () => {
        dispatch(deletePolls(deletedId))
        //console.log("delete id", id)
        //toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'Category contains FAQs and cannot be deleted!', life: 3000 });

    }
    const reject = () => {
        //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }
    const deletePoll = (id) => {
        deletedId=id;
        confirmDialog({
            message: 'Do you want to delete this Poll?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: accept,
            reject
        });
    };

    useEffect(() => {
        console.log("data",data)
        if (deleteSuccess !== undefined) {
            if (deleteSuccess === true) {
                dispatch(getPollsList());
            } else {
            }
        }
        return () => {
            dispatch(resetAddEditPolls());
        };
    }, [deleteSuccess, deleteError]);

    //Sub Poll delete dailoge
    let deletedSubId = "";
    const acceptSub = () => {
        dispatch(deleteSubPolls(deletedSubId))
        //console.log("delete subId", subId)
        //toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'Category contains FAQs and cannot be deleted!', life: 3000 });

    }
    const rejectSub = () => {
        //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }
    const deleteSubPoll = (subId) => {
        deletedSubId=subId;
        confirmDialog({
            message: 'Do you want to delete this Sub-Poll?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: acceptSub,
            reject:rejectSub
        });
    }
    useEffect(() => {
        if (deleteSubSuccess !== undefined) {
            if (deleteSubSuccess === true) {
                dispatch(getPollsList());
            } else {
            }
        }
        return () => {
            dispatch(resetAddEditSubPolls());
        };
    }, [deleteSubSuccess, deleteSubError]);
    const dispatch = useDispatch();
    const rowExpansionTemplate = (rowData) => {
        return (
            <DataTable showGridlines={true} value={rowData.submodule}>
                <Column field="name" header="Name" />
                <Column body={subModuleIconActionTemp} header="Icon" />
            </DataTable>);
    };
    // const allowExpansion = (rowData) => {
    //     return <>{rowData.length > 0}</>;
    // };
    const actionTemplate = (rowData,pollId) => {
        return (
            <>
                <div className="Edit_Icon">
                    <Button
                        tooltip="Edit Fields"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: "top" }}
                        className="edit m-2 p-button-rounded p-button-primary"
                        onClick={() => {
                            setEditForm(rowData);
                            setSelectedPolls(pollId)
                            onClick("showAddEditFormDailoge");
                        }}
                    />


                    {/* <Button
                        tooltip="Add Sub Module"
                        icon="pi pi-trash"
                        tooltipOptions={{ position: "top" }}
                        className="edit m-2 p-button-rounded p-button-danger"
                        onClick={() => {
                            setEditSubPoll(null);
                            onClick("showAddEditSubPollDialog");
                        }}
                    /> */}
                </div>
            </>);
    };
    // const addTemplate = (rowData) => {
    //     return (
    //         <>
    //             <Button
    //                 label="Add "
    //                 type="submit"
    //                 className="mr-3 p-button-primary p-button-rounded p-button-sm custom___width mb_width"
    //                 onClick={() => {
    //                     setEditForm(null);
    //                     onClick("showAddEditFormDailoge");
    //                 }}
    //             />
    //         </>);
    // };
    const subModuleIconActionTemp = (rowData) => {
        return (
            <>
                <i className={rowData.icon}></i>
            </>
        );
    };

    const pollsTemplate = (poll) => {
        //console.log(poll)


    }
    // const data = [{
    //     superADmin: "superAdmin",

    // }]
    useEffect(() => {
        dispatch(getPollsList());
    }, []);
    return (
        <>
            <Dialog header={editPoll == null ? "Add Polls" : "Edit Polls"} visible={showAddEditPollDialog} onHide={() => onHide("showAddEditPollDialog")} breakpoints={{ "960px": "75vw", "640px": "95vw" }} style={{ width: "50vw" }}>
                <AddEditPolls editData={editPoll} onHide={() => onHide("showAddEditPollDialog")} />
            </Dialog>
            <Dialog header={editSubPoll == null ? "Add Sub-Polls" : "Edit Sub-Polls"} visible={showAddEditSubPollDialog} onHide={() => onHide("showAddEditSubPollDialog")} breakpoints={{ "960px": "75vw", "640px": "95vw" }} style={{ width: "50vw" }}>
                <AddEditSubPolls editData={editSubPoll} onHide={() => onHide("showAddEditSubPollDialog")} />
            </Dialog>
            <Dialog header={editForm == null ? "Add Form" : "Edit Form"} visible={showAddEditFormDailoge} onHide={() => onHide("showAddEditFormDailoge")} breakpoints={{ "960px": "75vw", "640px": "95vw" }} style={{ width: "50vw" }}>
                <AddEditForm selectedPolls={selectedPolls} editData={editForm} onHide={() => onHide("showAddEditFormDailoge")} />
            </Dialog>
            {/* <div className="card">
        <div className="grid pb-3">
      
        </div>

     </div> */}
            <div className="card">
                <div className="grid pb-3">

                    <div className="col-12 md:col-6">
                        <h3>Polls Categories</h3>
                    </div>
                    <div className="col-12 md:col-6 text-right">
                        <span className="p-input-icon-right">
                            {/* <InputText className="mbl_view" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" /> */}
                            <Button
                                label="Add Sub Polls"
                                type="submit"
                                className="mbl_view mr-3 p-button-primary p-button-rounded p-button-sm custom___width mb_width"
                                onClick={() => {
                                    setEditSubPoll(null);
                                    onClick("showAddEditSubPollDialog");
                                }}
                            />
                            <Button
                                label="Add polls"
                                type="submit"
                                className="mbl_view mt-2  mr-3 p-button-primary p-button-rounded p-button-sm custom___width mb_width"
                                onClick={() => {
                                    setEditPoll(null);
                                    onClick("showAddEditPollDialog");
                                }}
                            />
                        </span>
                    </div>
                    {data?.map((poll) => {
                        return (
                            <>
                                {/* {
                                data?.map((poll)=>{
                                    return <>
                                    <h1>{poll.en_cat_title}</h1>
                                    {
                                        poll.subCat.map((subPoll)=>{
                                            return <>

                                            </>
                                        })
                                    }
                                    </>
                                })
                            } */}
                                <div className="col-12" >
                                    <Accordion className="">
                                        <AccordionTab header={
                                            <>
                                                <span className="polls_header mbl_view_font mr-3">{poll.en_cat_title}[{poll.ur_cat_title}]</span>
                                                <i className="pi pi-pencil mr-3" onClick={() => {
                                                    setEditPoll(poll);
                                                    //console.log("check poll id", setEditPoll(poll))
                                                    onClick("showAddEditPollDialog");
                                                }
                                                }></i >
                                                <i className="pi pi-trash mr-3" onClick={(e) => {
                                                    deletePoll(poll._id);
                                                    // setSelectedPolls(poll._id);
                                                    // //console.log(setSelectedPolls(poll._id))
                                                }}></i>
                                            </>
                                        }>
                                            {poll.subCat.map((sub) => {
                                                return (
                                                    <>
                                                        <div className="grid pb-3 mt-2">
                                                            {/* <div className="col-12 md:col-6">
                                                                <h4>Sub Polls</h4>
                                                            </div> 
                                                             <div className="col-12 md:col-6">
                                                                <div className="mb-9 text-right">
                                                                    <span className="p-input-icon-right">
                                                                         <InputText className="mbl_view" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />  
                                                                        <Button
                                                                            label="Add Sub Polls"
                                                                            type="submit"
                                                                            className="mr-3 p-button-primary p-button-rounded p-button-sm custom___width mb_width"
                                                                            onClick={() => {
                                                                                setEditSubPoll(null);
                                                                                onClick("showAddEditSubPollDialog");
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div> */}
                                                            <div className="col-8">
                                                                <h4>{sub.en_subCategoryTitle}[{sub.ur_subCategoryTitle}]</h4>
                                                            </div>
                                                            <div className="col-2 flex justify-content-end align-items-center mbl_view_width">
                                                                <Button
                                                                    tooltip="Edit Sub Module"
                                                                    icon="pi pi-pencil"
                                                                    tooltipOptions={{ position: "top" }}
                                                                    className="edit p-button-rounded p-button-primary"
                                                                    onClick={() => {
                                                                        setEditSubPoll(sub);
                                                                        ////console.log(setEditSubPoll(sub))
                                                                        onClick("showAddEditSubPollDialog");
                                                                    }}
                                                                />
                                                                <Button
                                                                    tooltip="Delete Sub Module"
                                                                    icon="pi pi-trash"
                                                                    tooltipOptions={{ position: "top" }}
                                                                    className="edit m-2 p-button-rounded p-button-danger"
                                                                    onClick={() => {
                                                                        deleteSubPoll(sub._id);
                                                                        ////console.log(sub._id)
                                                                    }}
                                                                />

                                                                {/* <i className="pi pi-pencil mr-3" onClick={() => {
                                                                    setEditSubPoll(sub);
                                                                    //console.log(setEditSubPoll(sub))
                                                                    onClick("showAddEditSubPollDialog");
                                                                }
                                                                }></i > */}
                                                            </div>
                                                            <div className="col-2 flex justify-content-start align-items-center">
                                                                <div className="mb-9 text-right">
                                                                    <span className="p-input-icon-right">
                                                                        <Button
                                                                            label="Add Fields"
                                                                            type="submit"
                                                                            className="mr-3 p-button-primary p-button-rounded p-button-sm custom___width mb_width"
                                                                            onClick={() => {
                                                                                setEditForm(null);
                                                                                setSelectedPolls(sub._id)
                                                                                //console.log("sub", sub._id)
                                                                                onClick("showAddEditFormDailoge");
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        {sub.fields.map((fields) => {
                                                            ////console.log("field", fields)
                                                            return (
                                                                <>
                                                                </>
                                                            )
                                                        })}
                                                        <>
                                                            <DataTable
                                                                filter
                                                                expandedRows={expandedRows}
                                                                rows={12}
                                                                paginator
                                                                value={sub.fields}
                                                                responsiveLayout="scroll"
                                                            // onRowToggle={(e) => {
                                                            //     setExpandedRows(e.data);
                                                            // }}
                                                            // rowExpansionTemplate={rowExpansionTemplate}
                                                            >
                                                                {/* <Column expander={allowExpansion} style={{ width: "3em" }} /> */}
                                                                <Column field="type" header="type"></Column>
                                                                <Column field="en_label" header="Eng Label"></Column>
                                                                <Column field="ur_label" header="Urdu Label"></Column>
                                                                <Column field="en_hint" header="Eng Hint"></Column>
                                                                <Column field="ur_hint" header="Urdu Hint"></Column>
                                                                <Column body={(rowData) => actionTemplate(rowData, sub.catId)} header="Icons"></Column>
                                                                {/* <Column body={addTemplate} header="Icons"></Column> */}
                                                            </DataTable>
                                                        </>
                                                    </>
                                                )
                                            })
                                            }
                                        </AccordionTab>

                                    </Accordion>

                                </div>
                            </>
                        )
                    })}




                    {/* <div className="col-12 md:col-6">
                        <h4>Sub Polls</h4>
                    </div>
                    <div className="col-12 md:col-6">
                        <div className="mb-9 text-right">
                            <span className="p-input-icon-right">
                                <InputText className="mbl_view" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />

                                <Button
                                    label="Add Sub Polls"
                                    type="submit"
                                    className="mr-3 p-button-primary p-button-rounded p-button-sm custom___width mb_width"
                                    onClick={() => {
                                        setEditSubPoll(null);
                                        onClick("showAddEditSubPollDialog");
                                    }}
                                />
                            </span>
                        </div>
                    </div> */}
                </div>
                {/* <DataTable
                    filter
                    expandedRows={expandedRows}
                    rows={12}
                    paginator
                    value={data}
                    responsiveLayout="scroll"
                    onRowToggle={(e) => {
                        setExpandedRows(e.data);
                    }}
                    rowExpansionTemplate={rowExpansionTemplate}>
                    <Column expander={allowExpansion} style={{ width: "3em" }} />
                    <Column field="name" header="Name"></Column>
                    <Column body={actionTemplate} header="Icons"></Column>
                    <Column body={addTemplate} header="Icons"></Column>
                </DataTable> */}

            </div>
        </>
    );
}