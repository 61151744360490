// import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button } from "primereact/button";
// import { toast } from "react-toastify";
import CustomInputField from "../../../components/custom_input_field";
import { InputText } from "primereact/inputtext";
import { getPollsList } from "../../../../redux/slices/polls_slice";
import { getUserList } from "../../../../redux/slices/user_slice";
import { addPolesController,getPollsControllerList, resetAddEditPolesController } from "../../../../redux/slices/polls_controller_slice";
import { toast } from "react-toastify";
// import { addTutorialsCategory, getTutorialsCategoryList, resetAddEditTutorialsCategory, updateTutorialsCategory } from "../../../../../redux/slices/tutorials_category_slice";

export default function AddEditPollControllerScreen({ onHide, editData }) {
    const pollsListReducer = useSelector((state) => state.pollsList);
    const { data, loading } = pollsListReducer;
    const pollsControllerListReducer = useSelector((state) => state.pollsControllerList);
    const { addSuccess } = pollsControllerListReducer;
    const userListReducer = useSelector((state) => state.userList);
    const { data: list } = userListReducer;
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        // name: Yup.string().required("Category is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            pollId: "",
            ROId: "",
        },

        onSubmit: async (data) => {
            if (editData === null) {
                dispatch(addPolesController(data));
                onHide();
                toast.success("Poll's Controller added");

            } else {
                // data["categoryId"] = editData._id;
                // dispatch(updateTutorialsCategory(data));
                ////console.log(data)
            }
        },
    });


    useEffect(() => {
        dispatch(getPollsList());
    }, []);
    useEffect(() => {
        dispatch(getUserList('app'));

    }, []);

    useEffect(() => {


        if (addSuccess !== undefined) {
          if (addSuccess === true) {
            // toast.success("Advertisement added");
    
            onHide();
            dispatch(getPollsControllerList());
    
          } else {
            // toast.error(addError);
    
          }
        }
    
       
    
        return () => {
          dispatch(resetAddEditPolesController());
        }
    
    
      }, [addSuccess]);


    const options = data?.map(option => ({
        label: option.en_cat_title + "," + option.ur_cat_title,
        value: option._id
    }));
    const secondOptions = list?.map(secondOptions => ({
        label: secondOptions.first_name + "," + secondOptions.last_name,
        value: secondOptions._id
    }));

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="">
                    <div className="grid p-fluid">
                        <div className="col-12">


                            <CustomInputField
                                className='col-12 md:col-12'
                                type='dropdown'
                                iden='pollId'
                                label="Choose Poll*"
                                formik={formik}
                                optionLabel="label"
                                optionValue="value"
                                options={options}
                                placeHolder="Please Select"
                            />
                            <CustomInputField
                                className='col-12 md:col-12'
                                type='dropdown'
                                iden='ROId'
                                label="Choose User*"
                                formik={formik}
                                optionLabel="label"
                                optionValue="value"
                                options={secondOptions}
                                placeHolder="Please Select"
                            />

                            {/* <CustomInputField iden='role' formik={formik} placeHolder='Select role' type='dropdown' options={data} optionLabel=' Political poll[سیاسی سروے]' optionValue='_id' /> */}
                            {/* <CustomInputField className='col-12 md:col-12' type='dropdown' iden='status' label="Chose Poll*" formik={formik} optionLabel="label" optionValue="value" options={[]} placeHolder="Please Select"/> */}
                            {/* <CustomInputField className='col-12 md:col-12' type='dropdown' iden='status' label="Chose User*" formik={formik} optionLabel="label" optionValue="value" options={[]} placeHolder="Please Select" /> */}

                            {/* <InputText label="Title" /> */}
                        </div>
                        <div className="col-12">
                            <div className="button_class-modal">
                                <Button
                                    //loading={editData === null ? addLoading : updateLoading}
                                    type="submit" label={editData === null ? "Add" : "Edit"} className="p-button-primary btn__default_modal p-button-rounded" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}
