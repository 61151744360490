import { configureStore } from "@reduxjs/toolkit";
import add_field_slice from "./redux/slices/add_field_slice.js";
import alerts_slice from "./redux/slices/alerts_slice";
import login_user_slice from "./redux/slices/login_user_slice";
import polls_category_slice from "./redux/slices/polls_category_slice";
import polls_controller_slice from "./redux/slices/polls_controller_slice";
import polls_slice from "./redux/slices/polls_slice";
import reports_slice from "./redux/slices/reports_slice";
import roles_slice from "./redux/slices/roles_slice";
import station_slice from "./redux/slices/station_slice";
import user_slice from "./redux/slices/user_slice";

// const user=localStorage.getItem("user");

const initialState = {
    // loginUser:{
    //   user:{
    //     "_id": "63544b08de3dfd30e94cc6dc",
    //     "name": "Super Admin",
    //     "email": "upserAdmin@copet.in",
    //     "role": {
    //         "_id": "635440b06bb94adbca28d14d",
    //         "name": "superAdmin"
    //     },
    //     "status": "active"
    // }
    // }
};

const store = configureStore({
    reducer: {
        // Define a top-level state field named `todos`, handled by `todosReducer`
        // loginUser:loginUserReducer,
        // driversList:getDriversListReducer,
        // usersList:getUsersListReducer,
        // updateUser:updateUserStatusRedcuer,
        // updateDriverDocumentsStatus:updateDriverDocumentsStatusReducer,
        // userById:getUserByIdReducer,
        // getUserRides:getUserRidesReducer,
        // getRideDetails:getRideDetailsReducer,
        loginUser:login_user_slice,
        rolesList:roles_slice,
        pollsList:polls_slice,
        subPollsList:polls_category_slice,
        addField:add_field_slice,
        pollsControllerList:polls_controller_slice,
        userList:user_slice,
        stationList:station_slice,
        alertList:alerts_slice,
        reportsList:reports_slice,
    },
    preloadedState: initialState,
});

export default store;
