import React  from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { Button } from 'primereact/button';
import { logout } from './app/redux/slices/login_user_slice';

export const AppTopbar = (props) => {

    const dispatch = useDispatch();
    const logoutUser = () => {
        dispatch(logout());

    }

    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
            <img src={'/assets/demo/images/product/reportinlogo.png'} alt="" />
            <h2>Reporting</h2>

                {/* <span>Force Tracking System</span> */}
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars"/>
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                    {/* <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-calendar"/>
                            <span>Events</span>
                        </button>
                    </li>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-cog"/>
                            <span>Settings</span>
                        </button>
                    </li> */}
                    <li>
                    <Button
                        className="p-link layout-topbar-button"
                        // tooltipOptions={{ position: "bottom" }}
                        onClick={logoutUser}
                        // tooltip="Log Out"
                    >
                        <i className="pi pi-power-off" />
                        <span>Profile</span>
                    </Button>
                </li>
                </ul>
        </div>
    );
}
