import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'


export default function StatsCardsItem({data}) {
    console.log(data)
    // const DashboardReducer = useSelector((state) => state.dashboardModule);
    // const { data: dashboardData } = DashboardReducer;
    const heading = (heading) => {
        return <h3 className='text-white custom__css'>
            {heading}
        </h3>
    }
    const value = (value) => {
        return <h1 className='text-white custom__css'>
            {value}
        </h1>
    }

    function toTitleCase(str) {
        return str.toLowerCase().split(' ').map(function (word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }
    return (
        <div className='grid'>
            <div className='col-12 md:col-3 sm:col-6'>
                <div className='flex flex-column bg-teal-300 p-5 border-round fixed__height '>
                    {heading('Total Supervisors')}
                    {value(data.totalSupervisor)}
                </div>
            </div>
            <div className='col-12 md:col-3 sm:col-6'>
                <div className='flex flex-column bg-green-500 p-5 border-round fixed__height '>
                    {/* {} */}
                    {/* {heading(toTitleCase(dashboardData.counts[0].countByStatus[0]?._id))} */}
                    {heading('Total Reporters')}
                    {value(data.totalReporter)}
                    {/* {value(dashboardData.counts[0].countByStatus.find((item)=>item._id==dashboardData.counts[0].countByStatus[0]?._id)?.count)} */}
                </div>
            </div>
            <div className='col-12 md:col-3 sm:col-6'>
                <div className='flex flex-column bg-purple-400 p-5 border-round fixed__height '>
                    {/* {heading(toTitleCase(dashboardData.counts[0].countByStatus[1]?._id))} */}
                    {heading('Total Polls')}
                    {value(data.totalCategory)}

                    {/* {value(dashboardData.counts[0].countByStatus.find((item)=>item._id==dashboardData.counts[0].countByStatus[1]?._id)?.count)} */}
                </div>
            </div>
            <div className='col-12 md:col-3 sm:col-6'>
                <div className='flex flex-column bg-yellow-400  p-5 border-round fixed__height '>
                    {/* {heading(toTitleCase(dashboardData.counts[0].countByStatus[2]?._id))} */}
                    {heading('Total Reports')}
                    {value(data.totalReports)}

                    {/* {value(dashboardData.counts[0].countByStatus.find((item)=>item._id==dashboardData.counts[0].countByStatus[2]?._id)?.count)} */}
                </div>
            </div>
            <div className='col-12 md:col-3 sm:col-6'>
                <div className='flex flex-column bg-cyan-900  p-5 border-round fixed__height '>
                    {/* {heading(toTitleCase(dashboardData.counts[0].countByStatus[2]?._id))} */}
                    {heading('Total Forwarded Reports')}
                    {value(data.todayForwarded)}

                    {/* {value(dashboardData.counts[0].countByStatus.find((item)=>item._id==dashboardData.counts[0].countByStatus[2]?._id)?.count)} */}
                </div>
            </div>
            <div className='col-12 md:col-3 sm:col-6'>
                <div className='flex flex-column bg-orange-400  p-5 border-round fixed__height '>
                    {/* {heading(toTitleCase(dashboardData.counts[0].countByStatus[2]?._id))} */}
                    {heading('Today Forwarded Reports')}
                    {value(data.totalForwardedReports)}

                    {/* {value(dashboardData.counts[0].countByStatus.find((item)=>item._id==dashboardData.counts[0].countByStatus[2]?._id)?.count)} */}
                </div>
            </div>
            <div className='col-12 md:col-3 sm:col-6'>
                <div className='flex flex-column bg-blue-400  p-5 border-round fixed__height '>
                    {/* {heading(toTitleCase(dashboardData.counts[0].countByStatus[2]?._id))} */}
                    {heading('Today Pending Reports')}
                    {value(data.todayPending)}

                    {/* {value(dashboardData.counts[0].countByStatus.find((item)=>item._id==dashboardData.counts[0].countByStatus[2]?._id)?.count)} */}
                </div>
            </div>
        </div>
    )
}
