import { getStationList } from "../redux/slices/station_slice";

//import { addRoles } from "../redux/auth_slice/roles_slice";
const appUrl = {
    fileBaseUrl:'http://20.212.227.60:3001/',
    baseUrl:'http://20.212.227.60:3001/',

    // fileBaseUrl:'http://192.168.12.97:3001/',
    //  baseUrl:'http://192.168.12.97:3001/',
    // fileBaseUrl:'http://qa-forcetrack.appinsnap.com/',
    // baseUrl:'http://qa-forcetrack.appinsnap.com/',
    // fileBaseUrl:'http://20.212.227.60:3025/',
    // baseUrl:'http://20.212.227.60:3025/',
    // fileBaseUrl: "http://192.168.4.240:3001/",
    // baseUrl: "http://192.168.4.240:3001/",
    // baseUrl:'http://liveapp.ctairkib.com:3003/',
    // adminLogin:'api/web/login',
    // getDriversList:'api/web/user/userByType',
    // getPassengersList:'api/web/user/userByType',
    // updateUserStatus:'api/web/user/activeStatus',
    // updateDriverDocumentStatus:'api/web/user/documentStatus',
    // getUserById:'api/web/user/userProfile',
    // getUserRides:'api/web/rides',
    // getRideDetails:'api/web/rides/rideById',
    // adminLogin: "api/admin/login",
    adminLogin: "api/users/login",
    getRolesList: 'new/api/role',
    addRoles: 'new/api/role',
    editRoles: 'new/api/role',
    //Polls Endpoints
    getPollsList: "new/api/poll/list",
    addPolls: "new/api/poll",
    updatePolls: "new/api/poll",
    deletePolls: "new/api/poll",
    addSubPolls: "new/api/poll/sub",
    updateSubPolls: "new/api/poll/sub",
    deleteSubPolls:"new/api/poll/sub",
    addFields:"new/api/poll/field",
    updateFields:"new/api/poll/field",
    //Polls  Controller Endpoints
    getPollsControllerList: 'new/api/pollRO',
    addPolesController: "new/api/pollRO",
    
   //station
   getStationList:"new/api/ps",
   addStation:"new/api/ps/officer",
   deleteStation:'new/api/ps/officer',
   //reports
   getReportsList:"new/api/report",
   //user
   getUserList:"new/api/user",
   addUser:"new/api/user/register",
getReportsListById:'new/api/report/details'
}
export default appUrl;