import Axios from 'axios';
import appUrl from '../../constants/appUrl';
// import { axiosApi } from '../../app/features/constants/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const pollSlice = createSlice({
    name: 'pollSlice',
    initialState: {},
    reducers: {
        resetAddEditPolls(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined }
        }


    },
    extraReducers: (builder) => {
        builder
            .addCase(getPollsList.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getPollsList.fulfilled, (state, action) => {

                return { ...state, loading: false, data: action.payload }
            })
            .addCase(getPollsList.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });

        builder
            .addCase(addPolls.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addPolls.fulfilled, (state, action) => {

                return { ...state, addLoading: false, addData: action.payload, addSuccess: true }
            })
            .addCase(addPolls.rejected, (state, action) => {

                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false
                }
            });
        builder
            .addCase(updatePolls.pending, (state, action) => {
                return { ...state, editLoading: true }
            })
            .addCase(updatePolls.fulfilled, (state, action) => {

                return { ...state, editLoading: false, editData: action.payload, editSuccess: true }
            })
            .addCase(updatePolls.rejected, (state, action) => {

                return {
                    ...state, editLoading: false,
                    error: action.payload,
                    editSuccess: false
                }
            });
        builder
            .addCase(deletePolls.pending, (state, action) => {
                return { ...state, deleteLoading: true }
            })
            .addCase(deletePolls.fulfilled, (state, action) => {

                return { ...state, deleteLoading: false, deleteData: action.payload, deleteSuccess: true }
            })
            .addCase(deletePolls.rejected, (state, action) => {

                return {
                    ...state, deleteLoading: false,
                    error: action.payload,
                    deleteSuccess: false
                }
            });
    },
});

export default pollSlice.reducer;
export const { resetAddEditPolls } = pollSlice.actions;



// Thunks
export const getPollsList = createAsyncThunk('getPollsList/fetch', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.get(appUrl.baseUrl + appUrl.getPollsList);
        ////console.log("", data);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const addPolls = createAsyncThunk('addPolls/add', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.post(appUrl.baseUrl + appUrl.addPolls, body);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }
});
export const updatePolls = createAsyncThunk('updatePolls/edit', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.put(appUrl.baseUrl + appUrl.updatePolls, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }

});
export const deletePolls = createAsyncThunk('deletePolls/delete', async (pollId, { rejectWithValue, fulfillWithValue }) => {
    try {

        const { data } = await Axios.delete(appUrl.baseUrl + appUrl.deletePolls + `?pollId=${pollId}`);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }
});
