import React from 'react';

export const AppFooter = (props) => {

    return (
        <div className="layout-footer">
           {/* <img src={'/assets/demo/images/product/glogo.png'} alt="logo" /> */}
           {/* <img className='footer__img' src={'/assets/demo/images/product/glogo.png'} alt="logo"/> */}
           {/* <h2>Reporting</h2> */}
        
        </div>
    );
}
