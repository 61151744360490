import Axios from 'axios';
import appUrl from '../../constants/appUrl';
// import { axiosApi } from '../../app/features/constants/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const   pollsSlice = createSlice({
    name: 'pollsSlice',
    initialState: {},
    reducers: {
        resetAddEditPolesController(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined }
        }


    },
    extraReducers: (builder) => {
        builder
            .addCase(getPollsControllerList.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getPollsControllerList.fulfilled, (state, action) => {

                return { ...state, loading: false, data: action.payload }
            })
            .addCase(getPollsControllerList.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });

        builder
            .addCase(addPolesController.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addPolesController.fulfilled, (state, action) => {

                return { ...state, addLoading: false, addData: action.payload, addSuccess: true }
            })
            .addCase(addPolesController.rejected, (state, action) => {

                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false
                }
            });
      
    },
});

export default pollsSlice.reducer;
export const { resetAddEditPolesController } = pollsSlice.actions;



// Thunks
export const getPollsControllerList = createAsyncThunk('getPollsControllerList/fetch', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.get(appUrl.baseUrl + appUrl.getPollsControllerList);
         //console.log(data);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const addPolesController = createAsyncThunk('addPolesController/add', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.post(appUrl.baseUrl + appUrl.addPolesController, body);
//console.log(data);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }
});
