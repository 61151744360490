import { GMap } from "primereact/gmap";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { loadGoogleMaps, removeGoogleMaps } from "../../../components/google-maps";
import { Tooltip } from 'react-bootstrap';

export default function MapLocation(props) {



  const { agents,alerts} = props;
  
  console.log({alerts:alerts.length})

  const google = window.google;
  const [googleMapsReady, setGoogleMapsReady] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [overlays, setOverlays] = useState([]);
  const mapRef = useRef(null);

  useEffect(() => {
    loadGoogleMaps(() => {
      setGoogleMapsReady(true);
    });

    return () => {
      removeGoogleMaps();
    };
  }, []);


  useEffect(() => {
    let agentLocations=[];
    let alertsLocations=[]
    if (agents?.length > 0) {
      agentLocations = agents?.map((d) => {
        return({ lat: d?.lastLocation?.latitude, lng: d?.lastLocation?.longitude })});
    } 
   
    if (alerts?.length > 0) {
       
         alertsLocations = alerts?.map((d) => {
           return({ lat: d?.location?.latitude, lng: d?.location?.longitude })});
          

       } 


    if (googleMapsReady) {
        let markers=[]
       markers = [...markers,...addAgentMarker(agentLocations),...addAlertMarkers(alertsLocations)];
      setOverlays((prev) => [...prev, ...markers]);
    }
  }, [googleMapsReady, agents,alerts]);


  const addAgentMarker = (locations) => {
   
    if (!agents || agents.length === 0) {
      return [];
    }
  
    const markers = locations.map((location, index) => {
      const markerIcon = "/assets/man.png";
  
      const marker = new google.maps.Marker({
        position: { lat: location.lat, lng: location.lng },
        id: `marker-${index}`,
        draggable: false,
        icon: {
          url: markerIcon,
          scaledSize: new google.maps.Size(25, 25),
          anchor: new google.maps.Point(25, 25),
        },
      });
      const respondedAt = new Date(agents[index]?.respondedAt);
      const date = respondedAt.toLocaleDateString();
      const time = respondedAt.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const formattedRespondedAt = `${date} ${time}`;
      // Create an InfoWindow for this marker
      const infoWindow = new google.maps.InfoWindow({
        // content: `
        //   <div><p>Responded Time: ${formattedRespondedAt}</p>
        //     <p>Resident Name: ${agents[index]?.name}</p>
        //     <p>Resident Number: ${agents[index]?.mobile }</p>
        //     <p>Category: ${agents[index]?.category }</p>
        //     <p>Address: ${agents[index]?.location.address }</p>
        //   </div>
        // `,
        content: `
        <div>
          <p>Agent Name: ${agents[index]?.first_name+" "+agents[index]?.last_name}</p>
        </div>
      `,
      });
  
      // Add mouseover event listener to open the   InfoWindow when the marker is hovered over
      marker.addListener("mouseover", () => {
        infoWindow.open(mapRef.current.getMap(), marker);
        setSelectedUser(agents[index]);
      });
  
      // Add mouseout event listener to close the InfoWindow when the marker is no longer hovered over
      marker.addListener("mouseout", () => {
        infoWindow.close();
        setSelectedUser(null);
      });
  
      return marker;
    });
  
    return markers;
  };
  

  const addAlertMarkers = (locations) => {
    // return []
 console.log(locations.length)
    const markers = locations.map((location, index) => {
      const markerIcon =agents[index]?.respondedA !=null ?'/assets/caution.png': "/assets/warning.png" 
      const marker = new google.maps.Marker({
        position: { lat: location.lat, lng: location.lng },
        id: `marker-${index}`,
        draggable: false,
        icon: {
          url: markerIcon,
          scaledSize: new google.maps.Size(25, 25),
          anchor: new google.maps.Point(25, 25),
        },
      });
      const respondedAt = new Date(alerts[index]?.respondedAt);
      const date = respondedAt.toLocaleDateString();
      const time = respondedAt.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const formattedRespondedAt = `${date} ${time}`;
      // Create an InfoWindow for this marker
      const infoWindow = new google.maps.InfoWindow({
        // content: `
        //   <div>
        //   <p>Responded Time: ${formattedRespondedAt}</p>
        //     <p>Resident Name: ${alerts[index]?.name}</p>
        //     <p>Resident Number: ${alerts[index]?.mobile }</p>
        //     <p>Category: ${alerts[index]?.category }</p>
        //     <p>Address: ${alerts[index]?.location.address }</p>
        //   </div>
        // `,
        content: `
        <div>
        <p>Responded Time: ${formattedRespondedAt}</p>
        <p>Resident Name: ${alerts[index].name}</p>
        <p>Resident Number: ${alerts[index]?.mobile }</p>
        <p>Category: ${alerts[index]?.category }</p>
        <p>Address: ${alerts[index]?.location.address }</p>
                </div>
      `,
     });
  
      // Add mouseover event listener to open the   InfoWindow when the marker is hovered over
      marker.addListener("mouseover", () => {
        infoWindow.open(mapRef.current.getMap(), marker);
        setSelectedUser(agents[index]);
      });
  
      // Add mouseout event listener to close the InfoWindow when the marker is no longer hovered over
      marker.addListener("mouseout", () => {
        infoWindow.close();
        setSelectedUser(null);
      });
  
      return marker;
    });
  
    return markers;
  };
  
  const onMapClick = (event) => {};

  const history = useHistory();

  const onOverlayClick = (event) => {
    let isMarker = event.overlay.getTitle !== undefined;
    if (isMarker) {
      const lat = event.overlay.getPosition().lat();
      const lng = event.overlay.getPosition().lng();
      window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, "_blank");
    }
  };

  const handleDragEnd = (event) => {};

  const defaultProps = {
    center: {
      lat: 33.6109,
      lng: 73.1730,
    },
    zoom: 14,
  };
  
  return (
    <div>
      {googleMapsReady && ( 
        <div className="card">
          <h4>Alert locations</h4>
          <GMap
            ref={mapRef}
            overlays={overlays}
            options={defaultProps}
            style={{ width: "100%", minHeight: "500px" }}
            onMapClick={onMapClick}
            onOverlayClick={onOverlayClick}
            onOverlayDragEnd={handleDragEnd}
          >
          {selectedUser && (
    <Tooltip target={`marker-${agents.indexOf(selectedUser)}`}>
      <div>{selectedUser.name}</div>
      {/* <div>{selectedUser.email}</div>
      <div>{selectedUser.phone}</div> */}
    </Tooltip>
  )}
          </GMap>
        </div>
      )}
    </div>
  );
}
