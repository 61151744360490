import Axios from 'axios';
import appUrl from '../../constants/appUrl';
// import { axiosApi } from '../../app/features/constants/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const permotionSlice = createSlice({
    name: 'permotionSlice',
    initialState: {},
    reducers: {
        resetAddEditRoles(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined }
        }


    },
    extraReducers: (builder) => {
        builder
            .addCase(getRolesList.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getRolesList.fulfilled, (state, action) => {

                return { ...state, loading: false, data: action.payload }
            })
            .addCase(getRolesList.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });

        builder
            .addCase(addRoles.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addRoles.fulfilled, (state, action) => {

                return { ...state, addLoading: false, addData: action.payload, addSuccess: true }
            })
            .addCase(addRoles.rejected, (state, action) => {

                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false
                }
            });
        builder
            .addCase(editRoles.pending, (state, action) => {
                return { ...state, editLoading: true }
            })
            .addCase(editRoles.fulfilled, (state, action) => {

                return { ...state, editLoading: false, editData: action.payload, editSuccess: true }
            })
            .addCase(editRoles.rejected, (state, action) => {

                return {
                    ...state, editLoading: false,
                    error: action.payload,
                    editSuccess: false
                }
            });
        builder
            .addCase(deleteRoles.pending, (state, action) => {
                return { ...state, deleteLoading: true }
            })
            .addCase(deleteRoles.fulfilled, (state, action) => {

                return { ...state, deleteLoading: false, deleteData: action.payload, deleteSuccess: true }
            })
            .addCase(deleteRoles.rejected, (state, action) => {

                return {
                    ...state, deleteLoading: false,
                    error: action.payload,
                    deleteSuccess: false
                }
            });
    },
});

export default permotionSlice.reducer;
export const { resetAddEditRoles } = permotionSlice.actions;



// Thunks
export const getRolesList = createAsyncThunk('getRolesList/fetch', async (flag, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.get(appUrl.baseUrl + appUrl.getRolesList+`?flag=${flag}`);
         console.log("rolelist",data);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const addRoles = createAsyncThunk('addRoles/add', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.post(appUrl.baseUrl + appUrl.addRoles, body);
//console.log(data);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }
});
export const editRoles = createAsyncThunk('editRoles/edit', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.put(appUrl.baseUrl + appUrl.editRoles, body);
        return fulfillWithValue(data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }

});
export const deleteRoles = createAsyncThunk('ads/delete', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {

        const { data } = await Axios.delete(appUrl.baseUrl + appUrl.deleteRoles + `?id=${body.adId}`);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }
});
