import Axios from 'axios';
import appUrl from '../../constants/appUrl';
// import { axiosApi } from '../../app/features/constants/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const fieldSlice = createSlice({
    name: 'fieldSlice',
    initialState: {},
    reducers: {
        resetAddEditFields(state, action) {
            return { ...state, addSuccess: undefined, updateSuccess: undefined, deleteSuccess: undefined }
        }


    },
    extraReducers: (builder) => {
        builder
            .addCase(addFields.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addFields.fulfilled, (state, action) => {

                return { ...state, addLoading: false, addData: action.payload, addSuccess: true }
            })
            .addCase(addFields.rejected, (state, action) => {

                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false
                }
            })
            .addCase(updateFields.pending, (state, action) => {
                return { ...state, updateLoading: true };
            })
            .addCase(updateFields.fulfilled, (state, action) => {
                return { ...state, updateLoading: false, updateData: action.payload, updateSuccess: true };
            })
            .addCase(updateFields.rejected, (state, action) => {
                return {
                    ...state,
                    updateLoading: false,
                    updateError: action.payload,
                    updateSuccess: false
                };
            });
    },
});

export default fieldSlice.reducer;
export const { resetAddEditFields } = fieldSlice.actions;



// Thunks
export const addFields = createAsyncThunk('addFields/add', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.post(appUrl.baseUrl + appUrl.addFields, body);
        console.log(data);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }
});

export const updateFields = createAsyncThunk('updateFields/update', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.put(appUrl.baseUrl + appUrl.updateFields, body);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }
});


