import { GMap } from "primereact/gmap";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { loadGoogleMaps, removeGoogleMaps } from "../../../components/google-maps";
import { Tooltip } from 'react-bootstrap';
export default function MapLocation(props) {
  const { agents = [], alerts } = props;
  const google = window.google;
  const [googleMapsReady, setGoogleMapsReady] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [overlays, setOverlays] = useState([]);
  const mapRef = useRef(null);

  useEffect(() => {
    loadGoogleMaps(() => {
      setGoogleMapsReady(true);
    });

    return () => {
      removeGoogleMaps();
    };
  }, []);

  const locations = [
    { lat: 33.617291, lng: 73.162746 },
    { lat: 33.612764, lng: 73.160183 },
    { lat: 33.606670, lng: 73.161278 },
    { lat: 33.605039, lng: 73.158797 },
    { lat: 33.600621, lng: 73.154224 },
    { lat: 33.596863, lng: 73.142236 },
    { lat: 33.596476, lng: 73.140220 },
    { lat: 33.594962, lng: 73.141168 },
    { lat: 33.597007, lng: 73.150744 },
    { lat: 33.597096, lng: 73.155266 },
    { lat: 33.598624, lng: 73.157496 },
    { lat: 33.599069, lng: 73.159968 },
    { lat: 33.599621, lng: 73.161130 },
    { lat: 33.602300, lng: 73.165306 },
    { lat: 33.603730, lng: 73.169913 },
    { lat: 33.600012, lng: 73.181084 },
    { lat: 33.602708, lng: 73.190196 },
    { lat: 33.610402, lng: 73.195382 },
    { lat: 33.616766, lng: 73.190168 },
    { lat: 33.620198, lng: 73.183103 },
  ];
  const onMarkerClick = (event) => { }
  const addMarker = (lat, lng, id) => {
    return new google.maps.Marker(
      {
        position: { lat, lng },
        onClick: onMarkerClick, id,
        draggable: false,
        icon: {
          url: "/assets/marker.png",
          scaledSize: new google.maps.Size(25, 25),
          anchor: new google.maps.Point(25, 25),
        },
      });
  };
  useEffect(() => {
    let agentLocations = [];
    if (agents.length > 0) {
      agentLocations = agents?.map((d) => {
        return ({ lat: d?.lastLocation?.latitude, lng: d?.lastLocation?.longitude })
      });
    }



    if (googleMapsReady) {
      let markers = []
      markers = [...markers, ...addAgentMarker(agentLocations)];
      setOverlays((prev) => [...prev, ...markers]);

      const marker = addMarker(33.6109, 73.1730, "gulberg-green");
      setOverlays((prev) => [...prev, ...markers, marker, new google.maps.Polygon({ paths: locations, strokeOpacity: 0.5, strokeWeight: 1, fillColor: '', fillOpacity: 0.1 }),]);
    }
  }, [googleMapsReady, agents, alerts]);


  const addAgentMarker = (locations) => {

    if (!agents || agents.length === 0) {
      return [];
    }

    const markers = locations.map((location, index) => {
      const markerIcon = "/assets/marker.png";

      const marker = new google.maps.Marker({
        position: { lat: location.lat, lng: location.lng },
        id: `marker-${index}`,
        draggable: false,
        icon: {
          url: markerIcon,
          scaledSize: new google.maps.Size(25, 25),
          anchor: new google.maps.Point(25, 25),
        },
      });
      const respondedAt = new Date(agents[index]?.respondedAt);
      const date = respondedAt.toLocaleDateString();
      const time = respondedAt.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const formattedRespondedAt = `${date} ${time}`;
      // Create an InfoWindow for this marker
      const infoWindow = new google.maps.InfoWindow({
        content: `
        <div>
          <p>Agent Name: ${agents[index]?.first_name + " " + agents[index]?.last_name}</p>
        </div>
      `,
      });

      // Add mouseover event listener to open the   InfoWindow when the marker is hovered over
      marker.addListener("mouseover", () => {
        infoWindow.open(mapRef.current.getMap(), marker);
        setSelectedUser(agents[index]);
      });

      // Add mouseout event listener to close the InfoWindow when the marker is no longer hovered over
      marker.addListener("mouseout", () => {
        infoWindow.close();
        setSelectedUser(null);
      });

      return marker;
    });

    return markers;
  };


  const onMapClick = (event) => { };

  const history = useHistory();

  const onOverlayClick = (event) => {
    let isMarker = event.overlay.getTitle !== undefined;
    if (isMarker) {
      const lat = event.overlay.getPosition().lat();
      const lng = event.overlay.getPosition().lng();
      window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, "_blank");
    }
  };

  const handleDragEnd = (event) => { };

  const defaultProps = {
    center: {
      lat: 33.6109,
      lng: 73.1730,
    },
    zoom: 14,
  };

  return (
    <div>
      {googleMapsReady && (
        <div className="card">
          <h4>Staffs' locations</h4>
          <GMap
            ref={mapRef}
            overlays={overlays}
            options={defaultProps}
            style={{ width: "100%", minHeight: "500px" }}
            onMapClick={onMapClick}
            onOverlayClick={onOverlayClick}
            onOverlayDragEnd={handleDragEnd}
          >
            {selectedUser && (
              <Tooltip target={`marker-${agents.indexOf(selectedUser)}`}>
                <div>{selectedUser.name}</div>
                {/* <div>{selectedUser.email}</div>
      <div>{selectedUser.phone}</div> */}
              </Tooltip>
            )}
          </GMap>
        </div>
      )}
    </div>
  );
}
