// import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import CustomInputField from "../../../components/custom_input_field";
import { addPolls, editPolls, getPollsList, resetAddEditPolls, updatePolls } from "../../../../redux/slices/polls_slice";
import { toast } from "react-toastify";
//import { Toast } from "primereact/toast";
//import { addTutorialsCategory, getTutorialsCategoryList, resetAddEditTutorialsCategory, updateTutorialsCategory } from "../../../../../redux/slices/tutorials_category_slice";

export default function AddEditPolls({ onHide, editData }) {
    const pollListReducer = useSelector((state) => state.pollsList);
    const { addLoading, addError, editError, editLoading, addSuccess, editSuccess } = pollListReducer;

    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        ENTitle: Yup.string().required("English Title is required"),
        URTitle: Yup.string().required("Urdu Title is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            ENTitle: "",
            URTitle: ""
        },

        onSubmit: async (data) => {
            if (editData === null) {
                dispatch(addPolls(data));
                toast.success("Poll added");
                 onHide();
            } else {
                data["pollId"] = editData._id;
                dispatch(updatePolls(data));
                toast.success("Poll updated");
                onHide();
            }
        },
    });

    //properties
    useEffect(() => {
        if (editData != null) {
            loadInitialValues();
        }
    }, []);
    const loadInitialValues = () => {
        formik.setFieldValue("URTitle", editData.ur_cat_title);
        formik.setFieldValue("ENTitle", editData.en_cat_title);
    };

    // useEffect(() => {
    //     if (editData !== null) {
    //         formik.setFieldValue("name", editData.name);
    //     }
    // }, []);
    useEffect(() => {
        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                // toast.success("Poll added");
                onHide();
                dispatch(getPollsList());
            } else {
                // toast.error(addError);
            }
        }
        if (editSuccess !== undefined) {
            if (editSuccess === true) {
                //toast.success("Poll updated");

                onHide();
                dispatch(getPollsList());
            } else {
                //toast.error(editError);
            }
        }

        return () => {
            dispatch(resetAddEditPolls());
        };
    }, [addSuccess, editSuccess, addError, editError]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="">
                    <div className="grid p-fluid">
                        <div className="col-12">
                            <CustomInputField iden="ENTitle" label="English Title" type="text" formik={formik} />
                        </div>
                        <div className="col-12">
                            <CustomInputField iden="URTitle" label="اردو عنوان" type="text" formik={formik} />
                        </div>
                        <div className="col-12">
                            <div className="button_class-modal">
                                <Button
                                    loading={editData === null ? addLoading : editLoading}
                                    type="submit" label={editData === null ? "Add" : "Update"} className="p-button-primary btn__default_modal p-button-rounded" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}
