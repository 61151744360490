// import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button } from "primereact/button";
// import { toast } from "react-toastify";
import CustomInputField from "../../../components/custom_input_field";
import { addRoles, editRoles, getRolesList, resetAddEditRoles } from "../../../../redux/slices/roles_slice";
import ReportsLocation from "./reports_location";
import DetailsItem from "./details_item";
import { getReportsList, getReportsListById } from "../../../../redux/slices/reports_slice";
// import { addTutorialsCategory, getTutorialsCategoryList, resetAddEditTutorialsCategory, updateTutorialsCategory } from "../../../../../redux/slices/tutorials_category_slice";
import { Accordion, AccordionTab } from 'primereact/accordion';

export default function AddDetails({ onHide, editData }) {
  const reportsListReducer = useSelector((state) => state.reportsList);
  const { reportData:data, loading, } = reportsListReducer;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReportsListById(editData._id)); 
    

}, []);
  return (
    <>
      <form >
        <div className="">
          <div className="grid p-fluid">
            <div className="col-12">
              <div className="card">
                <div className='grid'>

                  <div className='col-12'>
                    {/* <Button icon="pi pi-arrow-left" className="p-button-rounded back__button" onClick={() => { history.push("/api/complaints/") }}/> */}
                    <h2 className='color-primary font-bold'>{''}
                      {/* &nbsp; 	{complaintDetails.department} */}
                    </h2>
                  </div>

                  <div className='col-12 md:col-6'>
                    <DetailsItem
                      heading='Submitted By:'
                      value={`${data?.submittedBy?.first_name} ${data?.submittedBy?.last_name}`}
                    />
                  </div>
                  <div className='col-12 md:col-6'>
                    <DetailsItem
                      heading='Category:'
                      value={data?.catId?.en_cat_title}
                    />
                  </div>

                  <div className='col-12 md:col-6'>
                    <DetailsItem
                      heading='Sub Category:'
                      value={data?.subCatId?.en_subCategoryTitle}
                    // valueColor={complaintDetails === 'pending' ? 'text-yellow-500' : 'text-danger'}
                    />
                  </div>
                  {/* <div className='col-12 md:col-6'>
                    <DetailsItem
                      heading='Fields:'
                      value={''}
                    // valueColor={complaintDetails === 'pending' ? 'text-yellow-500' : 'text-danger'}
                    />
                  </div> */}

                   <div className='col-12 md:col-6'>
                     <DetailsItem
                      heading='Attachments'
                      value={data?.attachments}
                    // valueColor={complaintDetails === 'pending' ? 'text-yellow-500' : 'text-danger'}
                    /> 
                  </div> 
                  {/* <div className='col-12 md:col-6'>
                    <DetailsItem
                      heading='Status'
                      value={data?.status}
                    />
                  </div>

                  <div className='col-12 md:col-6'>
                    <DetailsItem
                      heading='Comments'
                      value={data?.comments}
                    />
                  </div> */}

                  <div className='col-12 md:col-12'>
                    {/* <DetailsItem
    heading='Address'
    value={complaintDetails.location.address}
  /> */}
                    {/* <div className='grid'>
    <div className='col-12 md:col-3'>
      <h4 style={{ margin: '0' }} className='color-primary font-semibold pl-5'>
        Address
      </h4>
    </div>
    <div className='col-12 md:col-8'>
      <h4 >{""}</h4>
    </div>
  </div> */}
                  </div>
                  <div className='col-12 md:col-12'>
                    {/* <DetailsItem
    heading='Address'
    value={complaintDetails.location.address}
  /> */}
                    {/* <div className='grid'>
    <div className='col-12 md:col-3'>
      <h4 style={{ margin: '0' }} className='color-primary font-semibold pl-5'>
        Details
      </h4>
    </div>
    <div className='col-12 md:col-8'>
      <h4 >{""}</h4>
    </div>
  </div> */}
                  </div>
                  <div className='col-12 md:col-12'>


                  </div>
                  <div className='col-12 md:col-12'>
                {/* <ComplaintItem
                  heading='Address'
                  value={complaintDetails.location.address}
                /> */}
               
              </div>
              <div className='col-12 md:col-12'>
                <Accordion
                  className='feeSection___Acordian mb-3'
                >
                  <AccordionTab
                    header="Fields"
                  >
                    {/* {console.log("data sub cat",data?.subCatId.map((item)=>item))} */}
                    {
                      data?.subCatId?.fields?.map((item) => {
                        return <div >
                          <div className='grid'>
                            <div className='col-6'>
                              <div className='font-bold mb-4'>
                                label
                              </div>
                              <div className='mb-3'>
                                {item.en_hint}
                              </div>
                            </div>
                            <div className='col-6 text-center'>
                              <div className='font-bold mb-4'>
                                value
                              </div>
                              <div className='mb-3'>
                                {item.value}
                              </div>
                            </div>
                          </div>
                        </div>
                      })
                    }

                  </AccordionTab>
                </Accordion>

              </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <ReportsLocation report={editData} />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
