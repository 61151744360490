import Axios from 'axios';
import appUrl from '../../constants/appUrl';
// import { axiosApi } from '../../app/features/constants/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const subPollSlice = createSlice({
    name: 'subPollSlice',
    initialState: {},
    reducers: {
        resetAddEditSubPolls(state, action) {
            return { ...state, addSuccess: undefined, updateSuccess: undefined, deleteSubSuccess: undefined }
        }


    },
    extraReducers: (builder) => {

    builder
        .addCase(addSubPolls.pending, (state, action) => {
            return { ...state, addLoading: true }
        })
        .addCase(addSubPolls.fulfilled, (state, action) => {

            return { ...state, addLoading: false, addData: action.payload, addSuccess: true }
        })
        .addCase(addSubPolls.rejected, (state, action) => {

            return {
                ...state,
                addLoading: false,
                addError: action.payload,
                addSuccess: false
            }
        });
    builder
        .addCase(updateSubPolls.pending, (state, action) => {
            return { ...state, updateLoading: true }
        })
        .addCase(updateSubPolls.fulfilled, (state, action) => {

            return { ...state, updateLoading: false, updateData: action.payload, updateSuccess: true }
        })
        .addCase(updateSubPolls.rejected, (state, action) => {

            return {
                ...state, updateLoading: false,
                error: action.payload,
                updateSuccess: false
            }
        });
    builder
        .addCase(deleteSubPolls.pending, (state, action) => {
            return { ...state, deleteSubLoading: true }
        })
        .addCase(deleteSubPolls.fulfilled, (state, action) => {

            return { ...state, deleteSubLoading: false, deleteSubData: action.payload, deleteSubSuccess: true }
        })
        .addCase(deleteSubPolls.rejected, (state, action) => {

            return {
                ...state, deleteSubLoading: false,
                deleteSubError: action.payload,
                deleteSubSuccess: false
            }
        });
},
});

export default subPollSlice.reducer;
export const { resetAddEditSubPolls } = subPollSlice.actions;



// Thunks
export const addSubPolls = createAsyncThunk('addSubPolls/add', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.post(appUrl.baseUrl + appUrl.addSubPolls, body);
        return fulfillWithValue(data);
    } catch (error) {
  
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }
});
export const updateSubPolls = createAsyncThunk('updateSubPolls/edit', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.put(appUrl.baseUrl + appUrl.updateSubPolls, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }

});
export const deleteSubPolls = createAsyncThunk('deleteSubPolls/delete', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {

        const { data } = await Axios.delete(appUrl.baseUrl + appUrl.deleteSubPolls + `?subPollId=${body}`);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }
});
