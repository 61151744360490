import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from "primereact/api";
import { useDispatch, useSelector } from "react-redux";
import { getReportsList } from '../../../../redux/slices/reports_slice';
import AddDetails from '../components/add_details';

export default function ReportsScreen() {

    const reportsListReducer = useSelector((state) => state.reportsList);
    const { data, loading, } = reportsListReducer;
    // //console.log("adminUsers checking admin users",adminUsers)
  

    const [showAddUserDialog, setAddUserDialog] = useState(false);
    const [editUser, setEditUser] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });


    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const dialogFuncMap = {
        'showAddUserDialog': setAddUserDialog,

    }
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

   

    const actionsBodyTemplate = (rowData) => {
        return <div>
            <Button icon="pi pi-user-edit" className="p-button-rounded p-button-success" onClick={() => {
                setEditUser(rowData);
                onClick('showAddUserDialog');
            }} aria-label="Edit" />
            {/* <Button icon="pi pi-bookmark" className="p-button-rounded p-button-secondary" aria-label="Bookmark" /> */}

        </div>;

    }
    const actionTemplate = (rowData) => {
        return (
          <div className="Edit_Icon">
    
            <Button label='Details' onClick={() => {
              setEditUser(rowData);
              onClick('showAddUserDialog');
            }} />
          </div>
        )
      };
     
    const dispatch = useDispatch();
     
    useEffect(() => {
        dispatch(getReportsList()); 
    
    }, []);
    return (

        <>
            <Dialog header={ '   Report Location'} visible={showAddUserDialog} onHide={() => onHide('showAddUserDialog')} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '70vw' }} className="Modal_class">
              <AddDetails  editData={editUser} onHide={() => onHide("showAddUserDialog")} />
            </Dialog>
            <div className="card">
                {/* <div className='flex flex-row justify-content-between align-content-center align-items-center pb-3'> */}
                <div className='grid pb-3'>
                    <div className='col-12 md:col-6'>
                        <h4>Reports</h4>
                    </div>
                    <div className=" col-12 md:col-6 mb-9 text-right">
                                <span className="p-input-icon-right">
                                    <InputText
                                        className="mbl_view"
                                        value={globalFilterValue}
                                        onChange={onGlobalFilterChange}
                                        placeholder="Search"
                                    />
                                
                                </span>
                                
                            </div>
                 
                </div>

                <DataTable rows={10} paginator responsiveLayout="scroll"  value={data} key="_id" emptyMessage="No record found."
                  globalFilterFields={[
                    
                ]}>
                    
                    <Column filter field="submittedBy.first_name" header="First Name" />
                    <Column filter field="submittedBy.last_name" header="Last Name" />
                    <Column filter field="catId.en_cat_title" header="Poll" />
                    <Column filter field="subCatId.en_subCategoryTitle" header="Sub Poll" />
                    <Column filter field="createdAt" header="Created At" />
                    <Column filter field="updatedAt" header=" Last Updated At" />
                    <Column body={actionTemplate} header="Actions"></Column>
                </DataTable>
            </div>
        </>

    )
}


