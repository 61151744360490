import Axios from 'axios';
import appUrl from '../../constants/appUrl';
// import { axiosApi } from '../../app/features/constants/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const reportsSlice = createSlice({
    name: 'reportsSlice',
    initialState: {},
    reducers: {
        resetAddEditPolls(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined }
        }


    },
    extraReducers: (builder) => {
        builder
            .addCase(getReportsList.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getReportsList.fulfilled, (state, action) => {

                return { ...state, loading: false, data: action.payload }
            })
            .addCase(getReportsList.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });

            builder
            .addCase(getReportsListById.pending, (state, action) => {
                return { ...state, editLoading: true }
            })
            .addCase(getReportsListById.fulfilled, (state, action) => {

                return { ...state, editLoading: false, reportData: action.payload }
            })
            .addCase(getReportsListById.rejected, (state, action) => {

                return {
                    ...state,
                    editLoading: false,
                    error: action.payload
                }
            });

      
    },
   
});

export default reportsSlice.reducer;
export const { resetAddEditPolls } = reportsSlice.actions;



// Thunks
export const getReportsList = createAsyncThunk('getReportsList/fetch', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.get(appUrl.baseUrl + appUrl.getReportsList);
         console.log(data);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const getReportsListById = createAsyncThunk('getReportsListById/fetch', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.get(appUrl.baseUrl + appUrl.getReportsListById+  `/${id}`);
         ////console.log(data);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }
});