import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
// import { FilterMatchMode } from "primereact/api";
// import { InputText } from "primereact/inputtext";
import AddEditRoles from "../component/add_user_role";
import { getRolesList } from "../../../../redux/slices/roles_slice";

export default function RolesScreen() {
    //states

    const [editRole, setEditRole] = useState(null);
    const [showAddEditRoleDialog, setAddEditRoleDialog] = useState(false);


    //hooks
    const rolesListReducer = useSelector((state) => state.rolesList);
    const { data, loading } = rolesListReducer;
    const dialogFuncMap = {
        showAddEditRoleDialog: setAddEditRoleDialog,
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };
    const dispatch = useDispatch();

    const actionTemplate = (rowData) => {
        return (
            <>  
                <div className="Edit_Icon">
                    <Button
                        tooltip="Edit Roles"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: "top" }}
                        className="edit m-2 p-button-rounded p-button-primary"
                        onClick={() => {
                            setEditRole(rowData);
                            onClick("showAddEditRoleDialog");
                        }}
                    />
                 
                </div>
            </>);
    };
    
    useEffect(() => {
        dispatch(getRolesList('app'));
       
    }, []);

    
    return (
        <>
            <Dialog header={editRole == null ? "Add Roles" : "Edit Roles"} visible={showAddEditRoleDialog} onHide={() => onHide("showAddEditRoleDialog")} breakpoints={{ "960px": "75vw", "640px": "95vw" }} style={{ width: "50vw" }}>
                <AddEditRoles editData={editRole} onHide={() => onHide("showAddEditRoleDialog")} />
            </Dialog>
            {/* <Dialog header={editSubModule == null ? "Add Sub-Module" : "Edit Sub-Module"} visible={showAddEditSubModuleDialog} onHide={() => onHide("showAddEditSubModuleDialog")} breakpoints={{ "960px": "75vw", "640px": "95vw" }} style={{ width: "50vw" }}>
            </Dialog> */}
            <div className="card">
                <div className="grid pb-3">
                    <div className="col-12 md:col-6">
                        <h4>Roles</h4>
                    </div>
                    <div className="col-12 md:col-6">
                        <div className="mb-9 text-right">
                            <span className="p-input-icon-right">
                                {/* <InputText className="mbl_view" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" /> */}
                                <Button
                                    label="Add Roles"
                                    type="button"
                                    className="mr-3 p-button-primary p-button-rounded p-button-sm custom___width mb_width"
                                    onClick={() => {
                                        setEditRole(null);
                                        onClick("showAddEditRoleDialog");
                                    }}
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <DataTable
                    filter
                    key="_id"
                    rows={5}
                    paginator
                    value={data}
                    responsiveLayout="scroll">
                    {/* <Column filter body={nameTemplate} header="Name"></Column> */}
                    <Column field="roleTitle" header="Name"></Column>
                    {/* <Column field="roleTitle" header="Name"></Column> */}
                    {/* <Column body={actionTemplate} header="Icons"></Column> */}
                </DataTable>
            </div>
        </>);
}