import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";
import { useSelector } from "react-redux";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import socket from "./socket";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";
import DashboardScreen from "./app/features/screens/dashboard/pages/dashboard_screen";
import RolesScreen from "./app/features/screens/roles/pages/roles_screen";
import PollsScreen from "./app/features/screens/polls/pages/polls_screen";
import PollsControllerScreen from "./app/features/screens/pollsController/pages/polls_controller_screen";
import StationScreen from "./app/features/screens/stations/pages/station_screen";
import ReportsScreen from "./app/features/screens/reports/pages/reports_screen";
import ListScreen from "./app/features/screens/users/list/pages/list_screen";
import LoginScreen from "./app/features/screens/auth/pages/login_screen";
import AlertScreen from "./app/features/screens/alert/pages/alert_screen";
import WebUserScreen from "./app/features/screens/users/list/pages/web-user_screen";
import AlertTableScreen from "./app/features/screens/alert/pages/alert_table_screen";

const App = () => {
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const menu = [
        {
            label: "Home",
            items: [
                { label: "Alert Dashboard", icon: "pi pi-exclamation-triangle", to: "/" },
                { label: "Alert Report", icon: "pi pi-exclamation-triangle", to: "/alertTableScreen" },
            ],
        },
        {
            items: [
                {
                    label: "Dashboard",
                    icon: "pi pi-fw pi-home",
                    to: "/dashboard",
                },
            ],
        },

        {
            items: [
                {
                    label: "Control",
                    icon: "pi pi-fw pi-sitemap",
                    items: [
                        { label: "Roles", icon: "pi pi-user", to: "/roles" },
                        { label: "Polls", icon: "pi pi-filter-fill", to: "/polls" },
                        { label: "Polls Controler", icon: "pi pi-shield", to: "/polls-controller" },
                        { label: "Stations", icon: "pi pi-home", to: "/station" },
                    ],
                },
            ],
        },
        {
            items: [
                {
                    label: "Users",
                    icon: "pi pi-user",
                    items: [
                        { label: "App User", icon: "pi pi-user", to: "/list" },
                        { label: "Web User", icon: "pi pi-user", to: "/user" },
                    ],
                },
            ],
        },
        {
            items: [
                {
                    label: "Reports",
                    icon: "pi pi-slack",
                    to: "/reports",
                    // label: 'Alerts', icon: 'pi pi-exclamation-triangle', to: '/alerts'
                },
            ],
        },
    ];

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });
    const loginUser = useSelector((state) => state.loginUser);

    useEffect(() => {
        // Connect to the socket when the app loads
        socket.connect();
        socket.emit("join", "admin"); // Joining admin room
        return () => {
            socket.disconnect();
            socket.emit("leave", "admin");
        };
    }, []);
    return (
        <>
            <ToastContainer />
            {localStorage.getItem("user") === null ? (
                <Route path="/" component={LoginScreen} />
            ) : (
                <div className={wrapperClass} onClick={onWrapperClick}>
                    <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                    <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                    <div className="layout-sidebar" onClick={onSidebarClick}>
                        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                    </div>

                    <div className="layout-main-container">
                        <div className="layout-main">
                            {/* <InputText placeholder='This is text' type='text'/> */}
                            <Route path="/dashboard" exact component={DashboardScreen} />
                            <Route path="/roles" exact component={RolesScreen} />
                            <Route path="/polls" exact component={PollsScreen} />
                            <Route path="/polls-controller" exact component={PollsControllerScreen} />
                            <Route path="/station" exact component={StationScreen} />
                            <Route path="/reports" exact component={ReportsScreen} />
                            <Route path="/list" exact component={ListScreen} />
                            <Route path="/user" exact component={WebUserScreen} />

                            <Route path="/" exact component={AlertScreen} />
                            <Route path="/alertTableScreen" exact component={AlertTableScreen} />
                            {/* <Route path=/> */}

                            {/* <Route path="/" exact render={() => <Dashboard colorMode={layoutColorMode} location={location} />} /> */}
                        </div>

                        <AppFooter layoutColorMode={layoutColorMode} />
                    </div>

                    <AppConfig
                    // rippleEffect={ripple}
                    // onRippleEffect={onRipple}
                    // inputStyle={inputStyle}
                    // onInputStyleChange={onInputStyleChange}
                    // layoutMode={layoutMode}
                    // onLayoutModeChange={onLayoutModeChange}
                    // layoutColorMode={layoutColorMode}
                    // onColorModeChange={onColorModeChange}
                    />

                    <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                        <div className="layout-mask p-component-overlay"></div>
                    </CSSTransition>
                </div>
            )}
        </>
    );
};

export default App;
