import Axios from 'axios';
import appUrl from '../../constants/appUrl';
// import { axiosApi } from '../../app/features/constants/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const alertsSlice = createSlice({
    name: 'alertsSlice',
    initialState: {},
    reducers: {
        resetAddEditPolls(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined }
        }


    },
    extraReducers: (builder) => {
        builder
            .addCase(getPollsList.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getPollsList.fulfilled, (state, action) => {

                return { ...state, loading: false, data: action.payload }
            })
            .addCase(getPollsList.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });

      
    },
});

export default alertsSlice.reducer;
export const { resetAddEditPolls } = alertsSlice.actions;



// Thunks
export const getPollsList = createAsyncThunk('getPollsList/fetch', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.get(appUrl.baseUrl + appUrl.getPollsList);
         //console.log(data);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});