import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import AddEditList from '../components/add_edit_list';
import { useDispatch, useSelector } from "react-redux";
import { getUserList } from "../../../../../redux/slices/user_slice";
import WebUserList from "../components/web_user_list";

export default function WebUserScreen() {
    const ListUserReducer = useSelector((state) => state.userList);
    const { data, loading, } = ListUserReducer;
    // console.log("adminUsers checking admin users",adminUsers)
    const [showAddUserDialog, setAddUserDialog] = useState(false);
    const [editUser, setEditUser] = useState(null);
    const [showAddEditUserDialog, setAddEditUserDialog] = useState(false);


    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const dialogFuncMap = {
        showAddEditUserDialog: setAddEditUserDialog,

    }
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }



    const actionsBodyTemplate = (rowData) => {
        return <div>
            <Button icon="pi pi-user-edit" className="p-button-rounded p-button-success" onClick={() => {
                // setEditUser(rowData);
                // onClick('showAddUserDialog');
            }} aria-label="Edit" />
            {/* <Button icon="pi pi-bookmark" className="p-button-rounded p-button-secondary" aria-label="Bookmark" /> */}

        </div>;
    }
    const dispatch = useDispatch();
     
    useEffect(() => {
        dispatch(getUserList("web")); 
    
    }, []);
    return (
        <>
            <Dialog header={editUser == null ? 'Add User' : 'Edit User'} visible={showAddEditUserDialog} onHide={() => onHide('showAddEditUserDialog')} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '70vw' }} className="Modal_class">
           
                <WebUserList  editData={editUser} onHide={() => onHide("showAddEditUserDialog")}/>
            </Dialog>
            <div className="card">
                {/* <div className='flex flex-row justify-content-between align-content-center align-items-center pb-3'> */}
                <div className="grid pb-3">
                    <div className="col-12 md:col-6">
                        <h4>Users</h4>
                    </div>
                    <div className=" col-12 md:col-6 mb-9 text-right">
                        <span className="p-input-icon-right">
                            <InputText
                                className="mbl_view"
                                value={globalFilterValue}
                                onChange={onGlobalFilterChange}
                                placeholder="Search"
                            />
                            <Button label='Add new' className='p-button-primary ml-3 p-button-rounded p-button-sm custom___width' onClick={() => {
                                setEditUser(null);
                                onClick('showAddEditUserDialog')
                            }} />
                        </span>

                    </div>
                    {/* <div className='col-12 md:col-6'>
                        <div className="mb-9 text-right">
                            <span className="p-input-icon-right">
                              
                            </span>
                            <Button label='Add new' className='p-button-success p-button-rounded p-button-sm custom___width' onClick={() => {
                                setEditUser(null);
                                onClick('showAddUserDialog')
                            }} />
                        </div>
                    </div> */}
                </div>

                <DataTable rows={10} paginator responsiveLayout="scroll" value={data} key="_id" emptyMessage="No record found."
                    globalFilterFields={[
                        
                    ]}>

                    <Column filter field="first_name" header="First Name" />
                    <Column filter field="last_name" header="Last Name" />
                    <Column filter field="mobile" header="Mobile" />
                    <Column filter field="email" header="Email" />
                    <Column filter field="role.roleTitle" header="Role" />



                </DataTable>
            </div>
        </>
    );
}
