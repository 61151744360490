import Axios from 'axios';
import appUrl from '../../constants/appUrl';
// import { axiosApi } from '../../app/features/constants/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const userSlice = createSlice({
    name: 'userSlice',
    initialState: {},
    reducers: {
        resetAddEditPolls(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined }
        }


    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserList.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getUserList.fulfilled, (state, action) => {

                return { ...state, loading: false, data: action.payload }
            })
            .addCase(getUserList.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });

        builder
            .addCase(addUser.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addUser.fulfilled, (state, action) => {

                return { ...state, addLoading: false, addData: action.payload, addSuccess: true }
            })
            .addCase(addUser.rejected, (state, action) => {

                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false
                }
            });
     
  
    },
});

export default userSlice.reducer;
export const { resetAddEditPolls } = userSlice.actions;



// Thunks
export const getUserList = createAsyncThunk('getUserList/fetch', async (user, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.get(appUrl.baseUrl + appUrl.getUserList+`?flag=${user}`);
         //console.log(data);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const addUser = createAsyncThunk('addUser/add', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.post(appUrl.baseUrl + appUrl.addUser, body);

        return fulfillWithValue(data.data);
    } catch (error) {
  
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }
});