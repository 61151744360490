// import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button } from "primereact/button";
// import { toast } from "react-toastify";
import CustomInputField from "../../../components/custom_input_field";
import { addRoles, editRoles, getRolesList, resetAddEditRoles } from "../../../../redux/slices/roles_slice";
import { toast } from "react-toastify";
// import { addTutorialsCategory, getTutorialsCategoryList, resetAddEditTutorialsCategory, updateTutorialsCategory } from "../../../../../redux/slices/tutorials_category_slice";

export default function AddEditRoles({ onHide, editData }) {
    const rolesReducer = useSelector((state) => state.rolesList);
    const { addLoading, addError, updateError, updateLoading, addSuccess, updateSuccess } = rolesReducer;
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        title: Yup.string().required("title is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            title: "",
        },

        onSubmit: async (data) => {
            if (editData === null) {
                dispatch(addRoles(data));
                 toast.success("Roll added");
                 onHide();
            } else {
                // data["roleId"] = editData._id;
                // dispatch(editRoles(data));
                toast.success("Roll updated");
                 console.log(data);
                 onHide();
            }
        },
    });

    useEffect(() => {


        if (addSuccess !== undefined) {
          if (addSuccess === true) {
            // toast.success("Advertisement added");
    
            onHide();
            dispatch(getRolesList());
    
          } else {
            // toast.error(addError);
    
          }
        }
    
       
    
        return () => {
          dispatch(resetAddEditRoles());
        }
    
    
      }, [addSuccess]);

      useEffect(() => {
        //console.log("Edit data",editData);
        if (editData != null) {
          loadInitialValues();
        }
      }, []);
      const loadInitialValues = () => {
        formik.setFieldValue('title', editData.roleTitle);
    
      }
    

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="">
                    <div className="grid p-fluid">
                        <div className="col-12">
                            <CustomInputField iden="title" label="Title" type="text" formik={formik} />
                        </div>
                        <div className="col-12">
                            <div className="button_class-modal">
                                <Button
                                    //loading={editData === null ? addLoading : updateLoading}
                                    type="submit" label={editData === null ? "Add" : "Edit"} className="p-button-primary btn__default_modal p-button-rounded" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}
