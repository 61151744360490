// import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button } from "primereact/button";
// import { toast } from "react-toastify";
import CustomInputField from "../../../components/custom_input_field";
import { InputText } from "primereact/inputtext";
import { getPollsList } from "../../../../redux/slices/polls_slice";
import { getUserList } from "../../../../redux/slices/user_slice";
import { addPolesController } from "../../../../redux/slices/polls_controller_slice";
import { addStation, getStationList, resetAddeditStation } from "../../../../redux/slices/station_slice";
import { toast } from "react-toastify";
// import { addTutorialsCategory, getTutorialsCategoryList, resetAddEditTutorialsCategory, updateTutorialsCategory } from "../../../../../redux/slices/tutorials_category_slice";

export default function AddStationOfficerScreen({ onHide, editData }) {
    // const pollsListReducer = useSelector((state) => state.pollsList);
    // const { data, loading } = pollsListReducer;
    const userListReducer = useSelector((state) => state.userList);
    const { data: list } = userListReducer;
    const stationListReducer = useSelector((state) => state.stationList);
    const { data:newList , addSuccess} = stationListReducer;
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        // ps: Yup.string().required("Category is required"),
        // user: Yup.string().required("Category is required"),


    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            ps: "",
            user:"",
        },

        onSubmit: async (data) => {
            //console.log(data)
            if (editData === null) {
                dispatch(addStation(data));
                toast.success("Station officer added")

            } else {
                // data["categoryId"] = editData._id;
                // dispatch(updateTutorialsCategory(data));
                ////console.log(data)
            }
        },
    });

   
    // useEffect(() => {
    //     dispatch(getPollsList());
    // }, []);
    useEffect(() => {
        dispatch(getUserList('app'));
    }, []);
 
    // const options = data?.map(option => ({
    //     label: option.en_cat_title + "," + option.ur_cat_title,
    //     value: option._id
    // }));
    // const secondOptions = list?.map(secondOptions => ({
    //     label: secondOptions.first_name + "," + secondOptions.last_name,
    //     value: secondOptions._id
    // }));
    useEffect(() => {


        if (addSuccess !== undefined) {
          if (addSuccess === true) {
            // toast.success("Advertisement added");
    
            onHide();
            dispatch(getStationList());
    
          } else {
            // toast.error(addError);
    
          }
        }
    
       
    
        return () => {
          dispatch(resetAddeditStation());
        }
    
    
      }, [addSuccess]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="">
                    <div className="grid p-fluid">
                        <div className="col-12">

                        <CustomInputField
                                className='col-12 md:col-12'
                                type='dropdown'
                                iden='ps'
                                label="station*"
                                formik={formik}
                                optionLabel="name"
                                optionValue="_id"
                                options={newList}
                                placeHolder="Please Select"
                            />
                            <CustomInputField
                                className='col-12 md:col-12'
                                type='dropdown'
                                iden='user'
                                label="Choose User*"
                                formik={formik}
                                optionLabel="first_name"
                                optionValue="_id"
                                options={list}
                                placeHolder="Please Select"
                            />
                             

                        </div>
                        <div className="col-12">
                            <div className="button_class-modal">
                                <Button
                                    //loading={editData === null ? addLoading : updateLoading}
                                    type="submit" label={editData === null ? "Add" : "Edit"} className="p-button-primary btn__default_modal p-button-rounded" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}
