import React, { useEffect, useState } from 'react'
import socket from '../../../../../socket.js';
import { axiosApi } from "../../../../services/axios_api.js";
import MapLocation from "../components/map_location.js";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import './alert_screen.css';
import { DataTable } from "primereact/datatable";
import Timer from '../components/timer.js';
export default function AlertTableScreen() {
    const [mapData, setMapData] = useState();
    const [singleMapData, setSingleMapData] = useState({});
    const [dialogVisible, setDialogVisible] = useState(false);

    const [alerts, setAlerts] = useState([])
    const getMapData = async () => {
        const result = await axiosApi.get("http://localhost:3001/api/stat/mapdata");
        if (result) {
            setMapData(result?.data);
        }
    };
    useEffect(() => {
        // Listen for the newAlert event
        socket.on('newAlert', (response) => {
            const { msg, data } = JSON.parse(response)
            setAlerts(data)

        });

        socket.emit('alertList', { foo: 'bar' }, (response) => {
            const { msg, data } = response
            setAlerts(data)

        });
        
 
        // Unsubscribe from the newAlert event when the component unmounts
        return () => {
            socket.off('newAlert');
        };
    }, []);
    useEffect(() => {

        getMapData();
    }, []);
    // const DashboardReducer = useSelector((state) => state.dashboardModule);
    // const { data: dashboardData } = DashboardReducer;
    const heading = (heading) => {
        return <h3 className='text-white custom__css'>
            {heading}
        </h3>
    }
    const value = (value) => {
        return <h1 className='text-white custom__css'>
            {value}
        </h1>
    }

    function toTitleCase(str) {
        return str.toLowerCase().split(' ').map(function (word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }

    useEffect(() => {
        // Subscribe to a socket event
        socket.on('my-event', (data) => {
            //console.log(data);
        });

        // Unsubscribe from the socket event when the component unmounts
        return () => {
            socket.off('my-event');
        };
    }, []);

    const toggleDialog = () => {
        setDialogVisible(!dialogVisible);
    };

    const handleDialog = (d) => {
        setSingleMapData(d)
        toggleDialog()
    }
    const createdAt = "2022-03-06T12:34:56.000Z"; // Replace with actual createdAt value from API
    return (
        <>

            {/* <Dialog visible={dialogVisible} onHide={() => setDialogVisible(false)}
                dialogClassName="custom-dialog-class">
                <MapLocation data={singleMapData} />
            </Dialog> */}
            <DataTable rows={10} paginator responsiveLayout="scroll" value={alerts} key="_id" emptyMessage="No record found."
                globalFilterFields={[
                    "driver.firstName",
                    "driver.lastName",
                    "driver.cnic",
                    "driver.email",
                    "driver.zindigiWallet.zindigiWalletNumber",
                ]}>

                <Column filter field="name" header="Name" />
                <Column filter field="mobile" header="Mobile" />
                <Column filter field="location.address" header="Address" body={(rowData) => {
                    const address = rowData.location.address;
                    const shortAddress = address.length > 20 ? `${address.slice(0, 20)}...` : address;
                    return <span>{shortAddress}</span>;
                }} />
                <Column filter field="respondedBy.first_name" header="Responded by" />
                <Column filter field="respondedAt" header="Responded Time" />
                <Column filter field="" header="Time Lap" body={(rowData) => <Timer createdAt={createdAt} />} />
                <Column filter field="closedTime" header="Close Time" />
                <Column filter field="createdAt" header="Created Time" />
                <Column filter field="category" header="Category" />
                <Column field="" header="Action" body={(rowData) => <Button onClick={(e) => handleDialog(rowData)} label="Location" />} />



            </DataTable>

        </>
    )
}
