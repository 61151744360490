// import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

// import { toast } from "react-toastify";
import { InputSwitch } from 'primereact/inputswitch';
import CustomInputField from "../../../../components/custom_input_field";
import { addUser, getUserList, resetAddEditPolls } from "../../../../../redux/slices/user_slice";
import { getRolesList } from "../../../../../redux/slices/roles_slice";
import { toast } from "react-toastify";
// import { addTutorialsCategory, getTutorialsCategoryList, resetAddEditTutorialsCategory, updateTutorialsCategory } from "../../../../../redux/slices/tutorials_category_slice";

export default function AddEditList({ onHide, editData }) {
    const ListUserReducer = useSelector((state) => state.userList);
    const { data, addLoading, addSuccess } = ListUserReducer;

    const rolesListReducer = useSelector((state) => state.rolesList);
    const { data: list } = rolesListReducer;
    //states
    const [selectedRole, setSelectedRole] = useState(null);


    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required("name  is required"),
        last_name: Yup.string().required("name is required"),
        email: Yup.string().required("email is required"),
        mobile: Yup.string().required("mobile is required"),
        role: Yup.string().required("role is required"),
        password: Yup.string().required("password is required"),
        pin: Yup.string().required("pin is required"),
      
        userName: Yup.string().required("pin is required"),
        // vehicleNumber: Yup.string().required("pin is required"),
        comment: Yup.string().required("comment is required"),


    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            userName: "",
            first_name: "",
            last_name: "",
            email: "",
            mobile: "",
            role: "",
            password: "",
            pin: "",
            create: true,
            view: false,
            edit: true,
            forward: true,
            comment: true,
            vehicle: false,
            vehicleType: "",
            vehicleNumber: "",
            l: ""

        },

        onSubmit: async (data) => {
            console.log(editData)
            console.log(data)
            if (editData === null) {
               
                dispatch(addUser(data));
                toast.success("App user added");
                onHide();

            } else {
                // data["categoryId"] = editData._id;
                // dispatch(updateTutorialsCategory(data));
                ////console.log(data)
            }
        },
    });
    useEffect(() => {
        dispatch(getRolesList('app'));

    }, []);
    useEffect(() => {


        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                // toast.success("Advertisement added");

                onHide();
                dispatch(getUserList('app'));

            } else {
                // toast.error(addError);

            }
        }



        return () => {
            dispatch(resetAddEditPolls());
        }


    }, [addSuccess]);
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="">
                    <div className="grid p-fluid">
                        <div className="col-6">
                            <CustomInputField iden="first_name" label="First Name" type="text" formik={formik} />
                        </div>
                        <div className="col-6">
                            <CustomInputField iden="last_name" label="Last Name" type="text" formik={formik} />
                        </div>

                        <div className="col-12">
                            <CustomInputField iden="email" label="Email" type="text" formik={formik} />
                        </div>
                        <div className="col-12">
                            <CustomInputField iden="mobile" label="Mobile" type="text" formik={formik} />
                        </div>
                        <div className="col-12">
                            <CustomInputField iden="userName" label="Username" type="text" formik={formik} />
                        </div>
                        <div className="col-12 dropdown">
                            <CustomInputField type='dropdown' iden='role' label="Choose Role" formik={formik} optionLabel="roleTitle" optionValue="_id" options={list} placeHolder="Please Select" onChange={handleRoleChange} />
                            <label >Role</label>
                            {/* <Dropdown options={list} optionLabel="roleTitle" placeHolder="Please Select" label="Role" optionValue="_id" value={selectedRole} onChange={(e) => {
                                const selectedOption=list.find(item=>e.target.value==item._id)
                                 setSelectedRole(selectedOption?.roleTitle) }} /> */}

                        </div>
                        <div className="col-12">
                            <h6>Report Permissions</h6>
                        </div>
                        
                        {(selectedRole != 'admin') && (
                            <>
                           

                             <div className="col">
                            <CustomInputField iden="create" formik={formik} label="create" type="checkbox" className="field col-12 md:col-4" />
                        </div>
                        <div className="col">
                            <CustomInputField iden="view" formik={formik} label="view" type="checkbox" className="field col-12 md:col-4" />
                        </div>
                        <div className="col">
                            <CustomInputField iden="edit" formik={formik} label="Edit" type="checkbox" className="field col-12 md:col-4" />
                        </div>
                        <div className="col">
                            <CustomInputField iden="forward" formik={formik} label="Forward" type="checkbox" className="field col-12 md:col-4" />
                        </div>
                        <div className="col">
                            <CustomInputField iden="comment" formik={formik} label="Comment" type="checkbox" className="field col-12 md:col-4" />
                        </div>

                        {/* <div className="col-12">
                            <CustomInputField type='dropdown' iden='role' label="Choose Role" formik={formik} optionLabel="roleTitle" optionValue="_id" options={list} placeHolder="Please Select" />
                        </div> */}
                        <div className="col-12">
                            <CustomInputField iden="vehicle" formik={formik} label="vehicle" type="checkbox" className="field col-12 md:col-4" />
                        </div>
                        {

                            formik.values.vehicle &&
                            <>
                                <div className="col-12">
                                    <CustomInputField iden="vehicleType" label="Vehicle Type" type="text" formik={formik} />
                                    <CustomInputField iden="vehicheNumber" label="vehicle No" type="text" formik={formik} />



                                </div>

                            </>


                        }




                    


                        <div className="col-12">
                            <CustomInputField iden="location" label="Location" type="text" formik={formik} />
                        </div>


                        <div className="col-6">
                            <CustomInputField iden="pin" label="PIN" type="text" formik={formik} />
                        </div></>
                        )}
                        
                       
                        <div className="col-6">
                               
                                <CustomInputField iden="password" label="Password" type="text" formik={formik} />
                            </div>
                        <div className="col-12">
                            <div className="button_class-modal">
                                <Button
                                    //loading={editData === null ? addLoading : updateLoading}
                                    type="submit" label={editData === null ? "Add" : "Edit"} className="p-button-primary btn__default_modal p-button-rounded" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </>
    );
}
