import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from "primereact/api";
import AddEditPollControllerScreen from '../components/add_edit_poll_contrler_screen';

import { getPollsControllerList } from '../../../../redux/slices/polls_controller_slice';
import { useDispatch ,useSelector} from 'react-redux';
export default function PollsControllerScreen() {

    // const adminUsersSelector = useSelector((state) => state.getAdminUsers);
    // const { adminUsers, loading, } = adminUsersSelector;
    // //console.log("adminUsers checking admin users",adminUsers)
    const pollsControllerListReducer = useSelector((state) => state.pollsControllerList);
    const { data, loading } = pollsControllerListReducer;
    const [expandedRows, setExpandedRows] = useState(null)
    
    const [showAddUserDialog, setAddUserDialog] = useState(false);
    const [showAddPollsDialog, setAddPollsDialog] = useState(false);
    
    const [editUser, setEditUser] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });


    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const dialogFuncMap = {
        'showAddUserDialog': setAddUserDialog,
        'showAddPollsDialog': setAddPollsDialog,

    }
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const dispatch = useDispatch();
     
    useEffect(() => {
        dispatch(getPollsControllerList()); 
       
    }, []);

    const actionsBodyTemplate = (rowData) => {
        return <div>
            <Button icon="pi pi-user-edit" className="p-button-rounded p-button-success" onClick={() => {
                setEditUser(rowData);
                onClick('showAddUserDialog');
            }} aria-label="Edit" />
            {/* <Button icon="pi pi-bookmark" className="p-button-rounded p-button-secondary" aria-label="Bookmark" /> */}

        </div>;
    }
    const rowExpansionTemplate = (rowData) => {

        return <DataTable
            showGridlines={true}
            value={rowData.polls}
        >
            <Column
                field='ur_cat_title'
                header='Polls'
            />

            <Column
                field='en_cat_title'
                header='Polls '
            />
           
           
        </DataTable>
    }
    const allowExpansion = (rowData) => {
        return <>
            {rowData.length > 0}
        </>
    }
   
    return (

        <>
            <Dialog header={editUser == null ? 'Add User' : 'Edit User'} visible={showAddUserDialog} onHide={() => onHide('showAddUserDialog')} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '70vw' }} className="Modal_class">
              <AddEditPollControllerScreen  editData={editUser} onHide={() => onHide("showAddUserDialog")}/>
            </Dialog>
           
            <div className="card">
                {/* <div className='flex flex-row justify-content-between align-content-center align-items-center pb-3'> */}
                <div className='grid pb-3'>
                    <div className='col-12 md:col-6'>
                        <h4>Polls Controller</h4>
                    </div>
                    <div className=" col-12 md:col-6 mb-9 text-right">
                                <span className="p-input-icon-right">
                                    <InputText
                                        className="mbl_view"
                                        value={globalFilterValue}
                                        onChange={onGlobalFilterChange}
                                        placeholder="Search"
                                    />
                                  <Button label='Add new' className='p-button-primary ml-3 p-button-rounded p-button-sm custom___width' onClick={() => {
                                setEditUser(null);
                                onClick('showAddUserDialog')
                            }} />
                                </span>
                                
                            </div>
                    {/* <div className='col-12 md:col-6'>
                        <div className="mb-9 text-right">
                            <span className="p-input-icon-right">
                              
                            </span>
                            <Button label='Add new' className='p-button-success p-button-rounded p-button-sm custom___width' onClick={() => {
                                setEditUser(null);
                                onClick('showAddUserDialog')
                            }} />
                        </div>
                    </div> */}
                </div>

                <DataTable rows={10} paginator responsiveLayout="scroll"  value={data} key="_id" emptyMessage="No record found."
                expandedRows={expandedRows}
                onRowToggle={(e) => { setExpandedRows(e.data) }}
                rowExpansionTemplate={rowExpansionTemplate}
                  globalFilterFields={[
                   
                ]}>
                        <Column expander={allowExpansion} style={{ width: '3em' }} />
                    
                    <Column filter field="first_name" header="First Name" />
                    <Column filter field="last_name" header="Last Name" />
                    {/* <Column filter field="" header="Mobile" />
                    <Column filter field="" header="Email" /> */}
                    {/* <Column filter field="" header="Polls" /> */}
                    
                   


                </DataTable>
            </div>
        </>

    )
}


