import moment from 'moment';
import React from 'react'
export default function DetailsItem({ heading, value, valueColor = 'text-color', template }) {

//   const trTemplate = (rowData) => {

//     let localTime = moment.utc(rowData.createdAt).local();
//     let currentTime = moment();
//     let timeDifference = currentTime.diff(localTime, 'minutes');
//     let difference = (rowData?.TAT - timeDifference) > 0 ? rowData?.TAT - timeDifference : 0;

//     return (
//       <>
//         {difference} minutes
//       </>
//     )
//   }
//   const timeElapsedTemplate = (rowData) => {

//     let localTime = moment.utc(rowData.createdAt).local();
//     let currentTime = moment();
//     let timeDifference = currentTime.diff(localTime, 'minutes');

//     return (
//       <>
//         {timeDifference} minutes
//       </>
//     )
//   }

  return (
    <div className='col-12 px-5'>
      <div className='grid'>

        <div className='col-12 md:col-6'>
          <h4 style={{ margin: '0' }} className='color-primary font-semibold'>
            {heading}
          </h4>
        </div>
        <div className='col-12 md:col-6'>
          <h4 style={{ margin: '0' }} className={`${valueColor}`}>
            {template ? template() : value}
          </h4>
        </div>
        {/* <div>
          {timeElapsedTemplate}
        </div>
        <div>
          {trTemplate}
        </div> */}
      </div>

    </div >
  )
}
